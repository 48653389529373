import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const AdditionalTrainingCatList = createAsyncThunk("additionaltraining/list", async (reqbody) => {


    if (reqbody == null && reqbody === undefined) {
        reqbody = {



            "source": "additional_training_list",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "email": "",
            "count": false,



        }
    }

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-training-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/***********Fetch Single data************** */

export const fetchSingleDataTraining = createAsyncThunk("fetch/singledata", async (reqBody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody, "source": "additional_training_list" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetch-single-training-lesson", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/***********************************For Edit Data Fetch******************** */

export const editDataFetchTraining = createAsyncThunk("additionaltraining/editdatatraining", async(reqBody)=>{

    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)
    const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}api1/training-fetch-data?_id=${reqBody}&source=${"additional_training_list"}`,
        // requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata", respdata)
    return respdata;
} );


/****************Change Status for training******************** */


// export const updateStatusTraining = createAsyncThunk("additionaltraining/statuschange", async (reqbody) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(reqbody)
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-training-status-change", requestOptions);
//     const respdata = await response.json();
//     return respdata;

// })


/*......................TrainingLessonList ......................*/

export const AdditionalTrainingLessList = createAsyncThunk("additionallessson/list", async (reqbody) => {


    if (reqbody == null && reqbody === undefined) {
        reqbody = 
        {
            "source": "additional_lesson_list",
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
             "searchcondition": {
        
           },
            "project": {},
            "count": false,

          
        }
    }

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-lesson-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});




/***********************************For Edit Datalesson Fetch******************** */

export const editDataFetchLessson = createAsyncThunk("additionallesson/editdata", async(reqBody)=>{

    console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)
    const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}api1/training-fetch-data?_id=${reqBody}&source=${"additional_lesson_list"}`,
        // requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata", respdata)
    return respdata;
} );

/***********Fetch Single data for lessson************** */

export const fetchinglessondata = createAsyncThunk("fetch/singledata", async (reqBody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody, "source": "additional_lesson_list" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetch-single-training-lesson", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/****************Change Status for lESSON******************** */


// export const updateStatusLesson = createAsyncThunk("additionallesson/statuschange", async (reqbody) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(reqbody)
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-lesson-status-change", requestOptions);
//     const respdata = await response.json();
//     return respdata;

// })

/**************************************for auto complete fetch********************* */

export const getAdditionalTrainingData = createAsyncThunk("getadditionaltrainnigdata/list", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "additional_training_list",
        "sort": {
            "type": "desc",
            "field": "name"
        },
        "searchcondition": {

        }
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-autocomplete-training", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});

export const getdataforadditionalcenter = createAsyncThunk("get/getdataforadditionalcenter", async (reqbody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)
    if (reqbody == null && reqbody === undefined) {
        reqbody = {
            "source": "additional_training_list",
            "sort": {
                "type": "asc",
                "field": "training_priority"
            },
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "searchcondition": {
                
            },
            "fetch":"category"
        }
    }

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-training-by-category", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const additionalleftdatafind = createAsyncThunk("fetch/additionalleftdatafind", async (reqBody) => {
    console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody, "source": "additional_lesson_list" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetch-single-training-lesson", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const additional_done_training = createAsyncThunk("post/additional_done_training", async (reqBody) => {
    console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-done-traning", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const additionalTraining = createSlice({

    name: 'additionaltraining',

    initialState: {
        submitting: false,
        loading: false,
        submitted: false,
        resMessage: null,
        successmsg: null,
        success: false,
        failed: false,
        additionaltrainingdata: [],
        fetchSingleDataTraining: null,
        additionallessondata: [],
        fetchinglessondata:null,
        editDataLoadingTraining: false,
        editDataTraining: null,
        editDataLoadingLesson: false,
        editDataLesson: null,
        // statusChangeFlagTraining: null,
        statusChangeFlagLesson:null,
        getadditionaltrainnigdata: [],



        additionalcenter_loader: false,
        addi_cat_data: [],
        addi_lesson_data: [],
        addi_left_data: {},
        firsttimeloadlessonshow:null,
        nodataflag:false



    },
    reducers: {
        resetnodataflag(state, action){
            state.nodataflag=false
        },
        resetalldatainaditionaltraingcenter(state, action) {
            state.firsttimeloadlessonshow = null;
            state.additionalcenter_loader= false;
            state.addi_cat_data= [];
            state.addi_lesson_data= [];
            state.addi_left_data= {};
        },
        resetfirsttimeloadlessonshow(state, action) {
            state.firsttimeloadlessonshow = null
        },
        resetaddi_left_data(state, action){
            state.addi_left_data={}
        },
        setInitialData(state, action) {
            state.loading = false;
            state.success = false;
            state.fetchSingleDataTraining = null;
            state.fetchinglessondata = null;
            state.getadditionaltrainnigdata= [];
        },
        // setdata(state, action) {
        //     state.additionallessondata = []

        // },
        setLessons(state, action) {
            state.additionallessondata = action.payload
        },
        clearEditDataAdditionalTraining(state, action) {
            // console.log("state from seteditdata==>", state, action);
            state.editDataTraining = null;
        },
        clearEditDataAdditionalLesson(state, action) {
            // console.log("state from seteditdata==>", state, action);
            state.editDataLesson = null;
        },
        clearAdditionalTrainingList(state, action) {
            state.additionaltrainingdata = [];
        },
        clearAdditionalLessonList(state, action) {
            state.additionallessondata = [];
        },


    },
    extraReducers: {

        
        [additional_done_training.rejected]: (state, action) => {
            state.additionalcenter_loader = false;
            state.successAction = false;

        },
        [additional_done_training.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.additionalcenter_loader = true;
        },
        [additional_done_training.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.additionalcenter_loader = false;
                // state.addi_left_data= action.payload.results?.res[0]

            }

        },
        [additionalleftdatafind.rejected]: (state, action) => {
            state.additionalcenter_loader = false;
            state.successAction = false;

        },
        [additionalleftdatafind.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.additionalcenter_loader = true;
        },
        [additionalleftdatafind.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.additionalcenter_loader = false;
                state.addi_left_data= action.payload.results?.res[0]

            }

        },

        [getdataforadditionalcenter.rejected]: (state, action) => {
            // state.loading = false;
            // state.successAction = false;

        },
        [getdataforadditionalcenter.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.additionalcenter_loader = true;
        },
        [getdataforadditionalcenter.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === "success") {
                state.additionalcenter_loader = false;

                if (action.payload.results?.res.length > 0 ) {
                    state.addi_lesson_data = [...state.addi_lesson_data,...action.payload.results.res]
                }else{
                    if(!state.nodataflag){
                        state.nodataflag=true
                    }
                }




                if (action.payload.training?.res.length > 0) {
                    state.addi_cat_data = [...state.addi_cat_data,...action.payload.training.res]
                }

                if(state.addi_left_data && Object.keys(state.addi_left_data).length==0 && action.payload.results?.res.length>0){
                    console.log("<<<<<< inside fetchSingleData >>>>");
                    state.firsttimeloadlessonshow=action.payload.results.res[0]._id
                    // state.addi_left_data=action.payload.results.res[0]
                    // store.dispatch(additionalleftdatafind(action.payload.results.res[0]._id))
                }



            }
        },

        /// #################### Additional Training category Listing ###############################
        [AdditionalTrainingCatList.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [AdditionalTrainingCatList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [AdditionalTrainingCatList.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.additionaltrainingdata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }

        },
        [fetchSingleDataTraining.pending]: (state, action) => {
            state.loading = true
            state.fetchSingleDataTraining = null

        },
        [fetchSingleDataTraining.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            if (action.payload.status === "success") {
                state.fetchSingleDataTraining = action.payload?.results?.res[0];

            }
        },
        [fetchSingleDataTraining.rejected]: (state, action) => {
            state.loading = false
        },

         /*****************Edit Data Training*************** */

         [editDataFetchTraining.pending]: (state, action) => {
            state.editDataLoadingTraining = true;


        },
        [editDataFetchTraining.fulfilled]: (state, action) => {
            state.editDataTraining = action.payload.results.res[0];
            state.editDataLoadingTraining = false;

        },
        [editDataFetchTraining.rejected]: (state, action) => {

        },

         //******************Update Status**************************** */

        //  [updateStatusTraining.pending]: (state, action) => {
        //     state.loading = true;
        //     state.success = false;
        //     state.statusChangeFlagTraining = true;

        // },
        // [updateStatusTraining.fulfilled]: (state, action) => {

        //     if (action.payload.status == 'success') {
        //         state.loading = false;
        //         //   state.success = true;
        //         state.submitting = false;
        //         state.statusChangeFlagTraining = false;

        //     }
        // },
        // [updateStatusTraining.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.statusChangeFlagTraining = false;
        // },





        /// #################### Additional Training Lesson Listing ###############################

        [AdditionalTrainingLessList.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [AdditionalTrainingLessList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [AdditionalTrainingLessList.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {
               
                state.additionallessondata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }

        },

        [fetchinglessondata.pending]: (state, action) => {
            state.loading = true
            state.fetchinglessondata = null

        },
        [fetchinglessondata.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            if (action.payload.status === "success") {
                state.fetchinglessondata = action.payload?.results?.res[0];

            }
        },
        [fetchinglessondata.rejected]: (state, action) => {
            state.loading = false
        },

          /*****************Edit Data Lesson*************** */

          [editDataFetchLessson.pending]: (state, action) => {
            state.editDataLoadingLesson = true;


        },
        [editDataFetchLessson.fulfilled]: (state, action) => {
            state.editDataLesson = action.payload.results.res[0];
            state.editDataLoadingLesson = false;

        },
        [editDataFetchLessson.rejected]: (state, action) => {

        },


           /*****************Edit Data Lesson*************** */

           [editDataFetchLessson.pending]: (state, action) => {
            state.editDataLoadingLesson = true;


        },
        [editDataFetchLessson.fulfilled]: (state, action) => {
            state.editDataLesson = action.payload.results.res[0];
            state.editDataLoadingLesson = false;

        },
        [editDataFetchLessson.rejected]: (state, action) => {

        },
            //******************Update Status**************************** */

            // [updateStatusLesson.pending]: (state, action) => {
            //     state.loading = true;
            //     state.success = false;
            //     state.statusChangeFlagLesson = true;
    
            // },
            // [updateStatusLesson.fulfilled]: (state, action) => {
    
            //     if (action.payload.status == 'success') {
            //         state.loading = false;
            //         //   state.success = true;
            //         state.submitting = false;
            //         state.statusChangeFlagLesson = false;
    
            //     }
            // },
            // [updateStatusLesson.rejected]: (state, action) => {
            //     state.loading = false;
            //     state.statusChangeFlagLesson = false;
            // },
             /// #################### USER LISting for lesson ###############################
        [getAdditionalTrainingData.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getAdditionalTrainingData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [getAdditionalTrainingData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                state.getadditionaltrainnigdata = action.payload.results.res;

            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },


    }
})

export default additionalTraining.reducer;
export const { clearAdditionalLessonList,clearAdditionalTrainingList,clearEditDataAdditionalTraining,clearEditDataAdditionalLesson,resetfirsttimeloadlessonshow,resetalldatainaditionaltraingcenter,resetaddi_left_data,resetnodataflag} = additionalTraining.actions;