import React, { useEffect, useState } from "react";
import "./Layout.css";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setloginRoute, userLogout } from "../Login/loginReducer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Snackbar, Tooltip } from "@mui/material";
import store from "../../store";
import { Cookies } from "react-cookie";
import { Box } from "@mui/system";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [isActive, setIsActive] = useState(false);
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [logoutClick, setLogoutClick] = useState(false);
  const [logoutSuccess, setLogoutSuccess] = useState(false);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // console.log("location ====", splitLocation);
  const isLoggedIn = useSelector((state) => {
    if (state.loginSlice.isLoggedIn !== null)
      return state.loginSlice.isLoggedIn;
    else return null;
  });
  const loginClick = (val) => {
    dispatch(setloginRoute(true));
    if (val !== null && val !== undefined) {
      navigate("/login");
    }
    // 
  };
  const [isHidedropdown, setHidedropdown] = useState(false);
  const hidedropdown = (event) => {
    setHidedropdown((current) => !current);
    setIsActive(false);
  };

  const clickedLogout = () => {
    let data = {
      id: store.getState().loginSlice?.userInfo?._id,
    };
    dispatch(userLogout(data));
    navigate("/");
    cookies.remove("userInfo", { path: "/" });
    cookies.remove("lastLoginTime", { path: "/" });
    cookies.remove("token", { path: "/" });
    cookies.remove("practiceContactData", { path: "/" });
    setLogoutClick(true);
  };

  // useEffect(() => {
  //   if (logoutSuccess) {
  //     setSnakbaropen(true);
  //     setLogoutClick(false);
  //     setLogoutSuccess(false);
  //     setSnackBarMsg("Logged Out Successfully");
  //     cookies.remove("userInfo", { path: "/" });
  //     cookies.remove("lastLoginTime", { path: "/" });
  //     cookies.remove("token", { path: "/" });
  //     setTimeout(() => {
  //       // navigate("/login");
  //     }, 800);
  //   }
  // }, [logoutSuccess]);

  // store.subscribe(() => {
  // const storeData = store.getState();
  // if (
  //   logoutClick &&
  //   storeData.loginSlice &&
  //   storeData.loginSlice.isLoggedIn === null
  // ) {
  //   setLogoutSuccess(true);
  //   setLogoutClick(false);
  // }
  // });
  store.subscribe(() => {
    const storeData = store.getState();
    if (storeData.loginSlice.success && storeData.loginSlice.message === "Logout Completed Successfully") {
      setLogoutClick(false);
    }
  })

  return (
    <div>
      {isLoggedIn && pathname !== "/login" && pathname !== "/404" ? (
        <div className="backLink backLinkHome" onClick={() => navigate("/dashboard")}  >
          <Tooltip title="Back to Dashboard" followCursor>
            <DashboardIcon />
          </Tooltip>
        </div>
      ) : null}
      {logoutClick && (
        <Box className='training_loaderTop' sx={{ width: "100%" }}>
          <app-progressbar></app-progressbar>
        </Box>
      )}
      <div className="headermainwrapper">
        <div className="common_width">
          <div className="header_colcontainer">
            <div className="header_leftblock">
              <div className="header_leftblock_subwrapper">
                <NavLink to="/" className="logowrapper">
                  <img
                    src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}iwc_logo.webp`}
                  />
                </NavLink>
              </div>
            </div>
            <div className={`header_centerblock ${isActive ? "showdiv" : ""}`}>
              <ul>
                <li>
                  <NavLink to="/" end onClick={() => isActive(false)}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <a
                    className={
                      splitLocation[1] === "dermacyte"
                        ? "active"
                        : "" || splitLocation[1] === "xwrap"
                          ? "active"
                          : ""
                    }
                  >
                    <span>Wound Product </span>
                  </a>

                  <ul
                    className={`dropdownmenu ${isHidedropdown ? "hodedropdown" : ""
                      }`}
                  >
                    <li>
                      <NavLink to="/dermacyte" onClick={hidedropdown}>
                        Dermacyte
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/xwrap" onClick={hidedropdown}>
                        Xwrap
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="/programs" onClick={hidedropdown}>
                    <span>Programs </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/trainings" onClick={hidedropdown}>
                    <span> Training </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/client-acquisition" onClick={hidedropdown}>
                    <span>Client Acquisition </span>
                  </NavLink>
                  {/* <ul className={`dropdownmenu ${isHidedropdown ? 'hodedropdown' : ''}`}>
                  <li>
                    <NavLink to="/weight-loss" onClick={hidedropdown}>
                    Doctor Supervised Weight Loss Clinic

                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/nutritional-counseling-detox" onClick={hidedropdown}>
                    Nutritional Counseling and Detoxification
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/anti-aging-therapy" onClick={hidedropdown}>
                    Anti-Aging Therapy Center
                    </NavLink>
                  </li>
                  <li>
                      <NavLink to="br-neurofeedback" onClick={hidedropdown}>
                    Neurofeedback
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/br-pulsed-electromagentic-field-therapy" onClick={hidedropdown}>
                    Pulsed Electromagnetic Field Therapy
                    </NavLink>
                  </li>

                 
                </ul>  */}
                </li>

                <li>
                  <NavLink to="/contact-us" onClick={hidedropdown}>
                    {" "}
                    Contact Us{" "}
                  </NavLink>
                </li>
                {isLoggedIn ? (
                  <li className="logout_class" onClick={() => clickedLogout()}>
                    <a href="javascript:void">Log out</a>
                  </li>
                ) : (
                  <li onClick={() => loginClick()}>
                    <a href="/login">Log in</a>
                    {/* <NavLink to="/login"> Log in</NavLink>    */}
                  </li>
                )}
              </ul>
            </div>
            <div className="header_rightblock">
              <div className="hide_button" onClick={() => loginClick(1)}>
                <button>Log in</button>
              </div>

              <div className="header_rightblock_number">
                <a href={process.env.REACT_APP_IS_IWC_NET && process.env.REACT_APP_IS_IWC_NET === "true" ? "tel:212.671.2978" : "tel:801.888.8435"}>{(process.env.REACT_APP_IS_IWC_NET && process.env.REACT_APP_IS_IWC_NET === "true") ? "212.671.2978" : "801.888.8435"}</a>
              </div>
              <div className="responsive_menu" onClick={handleClick}>
                <MenuIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snakbaropen}
        message={snackBarMsg}
        key="bottomcenter"
      />
    </div>
  );
}

export default Header;
