const NoPage = () => {
    return (<>
      <div className="error-page">
        {/* <img src={error_image} alt="not found page"/> */}
        <h1> 404 </h1>
        <h3> Page Not Found</h3>
      </div>
    </>);
  };
  
  export default NoPage;
  