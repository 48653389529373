import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import GetRoutes from "./config/routes";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// store.dispatch(fetchUsers());
import { CookiesProvider, withCookies, Cookies } from "react-cookie";



import './index.css';
import Home from "./Pages/Frontend/Home/Home";

import './assets/meterial-css/indigo-pink.css'

import { setLogedinUserInfo } from "./Pages/Login/loginReducer";
import Layout from './Layout';


const cookies = new Cookies();
console.log("cookies in layout==>", cookies.getAll());
console.log("environment==>", process.env);

const getcookies = cookies.getAll();
console.log("getcookies from index==>", getcookies);
if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
  store.dispatch(setLogedinUserInfo(getcookies));
}



function App() {


  return (

    <>



      <CookiesProvider>
        <Provider store={store}>
          <GetRoutes />
          {/* <Routes>
          <Route >

          <Route index element={<Suspense fallback={<></>}><Home /></Suspense>} />
          </Route>
          </Routes> */}
        </Provider>
      </CookiesProvider>
    </>
  );
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container);
root.render(<App />);