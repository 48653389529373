import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../../store";
import { setLoader, setResloved } from "../../Layout/layoutReducer";


// API call for users-list
export const gettraingcatagorydata = createAsyncThunk("gettraingcatagorydata", async (reqbody) => {

    console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body),

    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/traninglist", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
}, { unwrap: true });

export const fetchCPTICDCategories = createAsyncThunk("fetch/CPTICDCategories", async (reqBody) => {
    if (reqBody == null && reqBody === undefined) {
        reqBody = {
            "source": "category_list",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "priority",
                "type": "asc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        }
    }
    let req_body = {};
    req_body = { ...reqBody };
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-cpt-icd/category-list-count", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
export const fetchCPTICDList = createAsyncThunk("fetch/CPTICDList", async (reqBody) => {

    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-cpt-icd/fetch-cpt-codes", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
export const fetchCPTICDListCount = createAsyncThunk("fetch/CPTICDListCount", async (reqBody) => {

    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-cpt-icd/fetch-cpt-codes", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const getparacticelogourl = createAsyncThunk("getparacticelogourl", async (reqBody) => {

    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetch-logofile", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});





const dashboard = createSlice({
    name: 'dashboard',
    initialState: {
        submitting: false,
        loading: false,
        trainingcatagorydata: [],
        endpointSuccess: {},
        gettraingcatagorydataSuccess: false,
        CPTICDCategoryList: [],
        fetchCPTICDCategoryLoading: false,
        CPTICDList: [],
        CPTICDListCount: 0,
        fetchCPTICDLoading: false,
        practicelogo:{}

    },
    reducers: {
        resetendpointSuccess(state, action) {
            state.endpointSuccess = {}
            state.gettraingcatagorydataSuccess = false;
        },

    },
    extraReducers: {
        
        [getparacticelogourl.rejected]: (state, action) => {


        },
        [getparacticelogourl.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            // state.loading = true;
            console.log("gettraingcatagorydata=========================>")
        },
        [getparacticelogourl.fulfilled]: (state, action) => {

            console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                state.practicelogo = action.payload.results?.logo_file;
   

                // state.loading = false;
            }
        },

        /// #################### Training Listing ###############################
        [gettraingcatagorydata.rejected]: (state, action) => {
            state.endpointSuccess[action.type] = false
            // state.loading = false;
            state.successAction = false;
            state.gettraingcatagorydataSuccess = true;

        },
        [gettraingcatagorydata.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            // state.loading = true;
            console.log("gettraingcatagorydata=========================>")
        },
        [gettraingcatagorydata.fulfilled]: (state, action) => {

            console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                state.endpointSuccess[action.type] = true
                state.trainingcatagorydata = action.payload.results.res;
                state.gettraingcatagorydataSuccess = true;

                // state.loading = false;
            }
        },
        [fetchCPTICDCategories.rejected]: (state, action) => {
            // state.endpointSuccess[action.type] = false
            state.fetchCPTICDCategoryLoading = false;
            state.successAction = false;

        },
        [fetchCPTICDCategories.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.fetchCPTICDCategoryLoading = true;
            console.log("fetchCPTICDCategories=========================>")
        },
        [fetchCPTICDCategories.fulfilled]: (state, action) => {

            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                if (action.payload.results && action.payload.results.res) {
                    state.CPTICDCategoryList = action.payload.results.res;
                }

                state.fetchCPTICDCategoryLoading = false;
            }
        },
        [fetchCPTICDList.rejected]: (state, action) => {
            // state.endpointSuccess[action.type] = false
            state.fetchCPTICDLoading = false;
            state.successAction = false;

        },
        [fetchCPTICDList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.fetchCPTICDLoading = true;
            console.log("fetchCPTICDList=========================>")
        },
        [fetchCPTICDList.fulfilled]: (state, action) => {

            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                // state.endpointSuccess[action.type] = true
                if (action.payload.results && action.payload.results.res) {
                    state.CPTICDList = [];
                    state.CPTICDList = action.payload.results.res;
                }

                state.fetchCPTICDLoading = false;
            }
        },
        [fetchCPTICDListCount.rejected]: (state, action) => {
            // state.endpointSuccess[action.type] = false
            state.fetchCPTICDLoading = false;
            state.successAction = false;

        },
        [fetchCPTICDListCount.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.fetchCPTICDLoading = true;
            console.log("fetchCPTICDListCount=========================>")
        },
        [fetchCPTICDListCount.fulfilled]: (state, action) => {

            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                // state.endpointSuccess[action.type] = true
                if (action.payload.count ) {
                    state.CPTICDListCount = 0;
                    state.CPTICDListCount = action.payload.count;
                }

                state.fetchCPTICDLoading = false;
            }
        },



    }
})

export default dashboard.reducer;
export const { resetendpointSuccess } = dashboard.actions;