import React, { useEffect, useRef, useState } from "react";
import Header from "../../Layout/Header";
import UserSignUpForm from "../UserSignupForm";
import "../Home/Home.css";
import { useNavigate } from "react-router-dom";
import store from "../../../store";
import { Snackbar } from "@mui/material";
import { useDispatch } from "react-redux";
import { clearMessageIsLoggedIn } from "../../Login/loginReducer";
import { Box } from "@mui/system";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollToTestimonial = useRef();

  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [formSubmissionFetching, setFormSubmissionFetching] = useState(false);

  const cancerScreeningFun = () => {
    scrollToTestimonial.current.scrollIntoView({ behavior: "smooth" });
  };
  // console.log(
  //   `${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_home_banner_updt.webp`,
  //   "process.env------->",
  //   process.env
  // );

  useEffect(() => {
    if (logoutSuccess) {
      setSnakbaropen(true);
      setLogoutSuccess(false)
    } else {
      if (snakbaropen) {
        setTimeout(() => {
          setSnakbaropen(false);
          dispatch(clearMessageIsLoggedIn());
        }, 2000)
      }
    }
  }, [logoutSuccess])


  store.subscribe(() => {
    const storeData = store.getState();
    if (storeData.loginSlice.success && storeData.loginSlice.message === "Logout Completed Successfully") {
      setLogoutSuccess(true);
      setSnackBarMsg(storeData.loginSlice.message)
    }
  })
  const formSubmissionStatus = (event) => {
    // console.log("props",event)
    setFormSubmissionFetching(event)
  }

  return (
    <>
      {formSubmissionFetching && (
        <Box className='training_loaderTop' sx={{ width: "100%" }}>
          <app-progressbar></app-progressbar>
        </Box>
      )}
      <div className="home_banner_main_wrapper">
        <div className="common_width">
          <div className="home_banner_wrapper">
            <div className="banner_left_section">
              <h1>Instructive Wound Care</h1>
              <h2>
                The leaders in wound care programs <br /> and product for your
                practice
              </h2>
              <h3>
                Interested in offering wound care? Work with the professionals.
              </h3>
              <div className="banner_desc_wrapper">
                <p>
                  Instructive Wound Care is the ultimate resource for operating
                  a successful wound care management program in your practice.
                  We partner with our practice clients to bring about top
                  performance through training, high quality products, and
                  patient acquisition participation.{" "}
                </p>
                <p>
                  When you work with us, we bring you the ability to get started
                  with no up-front cost. This offer is extended to practices
                  that follow our wound care model, which maximizes their plan
                  of care performance. To date, practices that use our model
                  have experienced no charge backs or audits. Join us, and
                  experience the comfort of doing wound right.{" "}
                </p>

                <h4>Perfect for NPs, MDs and Family Practice Models </h4>
              </div>
            </div>

            <div className="banner_right_section" ref={scrollToTestimonial}>
              <div className="right_section_wrapp">
                <UserSignUpForm type="frontend" formSubmission={formSubmissionStatus} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_block1">
        <div className="common_width">
          <div className="block1_grid_wrapp common_padding">
            <div className="home_block1_grid grid_one">
              <div className="grid_heading">
                <p>Learn about our Programs</p>
              </div>
              <div className="grid_image">
                <img
                  src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_home_block1_firstimg.webp`}
                  alt=""
                />
              </div>
              <div className="grid_button grid_button_position">
                <button onClick={() => navigate("/programs")}>
                  Click Here
                </button>
              </div>
            </div>

            <div className="home_block1_grid grid_two">
              <div className="grid_heading">
                <p>Learn about our Training</p>
              </div>
              <div className="grid_image">
                <img
                  src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_home_block1_scndimg.webp`}
                  alt=""
                />
              </div>
              <div className="grid_button grid_button_position">
                <button onClick={() => navigate("/trainings")}>
                  Click Here
                </button>
              </div>
            </div>

            <div className="home_block1_grid grid_three">
              <div className="grid_heading">
                <p>Customer Acquisition</p>
              </div>
              <div className="grid_image">
                <img
                  src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_home_block1_thirdimg.webp`}
                  alt=""
                />
              </div>
              <div className="grid_button grid_button_position">
                <button onClick={() => navigate("/client-acquisition")}>
                  Click Here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_block2">
        <div className="common_width">
          <div className="block2_banner_wrapper">
            <h1>Learn about our Products</h1>
            <div className="block2_banner_logo_wrapper">
              <div className="banner_logo_wrap">
                <div className="block2_banner_logo grid_image">
                  <img
                    src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_home_bannerblock_logo1.webp`}
                    alt=""
                  />
                </div>
                <div className="grid_button ">
                  <button onClick={() => navigate("/dermacyte")}>Click Here</button>
                </div>
              </div>
              <div className="banner_logo_wrap logo_wrapper_differ">
                <div className="block2_banner_logo grid_image">
                  <img
                    src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_home_bannerblock_logo.webp`}
                    alt=""
                  />
                </div>
                <div className="grid_button ">
                  <button onClick={() => navigate("/xwrap")}>Click Here</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_block4">
        <div className="home_block4_wrapper block_padding">
          <div className="common_width">
            <div className="home_block4_content_wrap textwrapper">
              <p>
                Offering wound care to the patient population in your area
                really matters. We help you learn the true power of being a
                wound care provider. With the tools we give you, you’ll be able
                to make incredible differences in your patients’ lives. Our
                wound care products have a 92% success rate of healing wound
                that extend beyond the 30 day care cycle, and can save patients
                from expensive surgery and hospitalization. For these reasons,
                Medicare pays very well, allowing practices like yours to be
                available for programs like ours. We cover all this in our
                initial Zoom consultation.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home_block5">
        <div className="common_width">
          <div className="home_block5_wrapper">
            <img
              src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_lastblock_arrow.png`}
              alt=""
            />

            <div className="home_block5_leftimage_wrapper">
              <div className="home_block5_leftimage_innerwrapper">
                <img
                  src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}IWC_home_lastblock_img.webp`}
                  alt=""
                />
              </div>
            </div>
            <div className="home_block5_content_wrapper">
              <p>
                Let’s get you started! Fill out the form at the top of our site
                and schedule a Zoom meeting right away!
              </p>
            </div>
            <div
              className="beside_arrow_div_homelast"
              onClick={() => cancerScreeningFun()}
            ></div>
          </div>
        </div>
      </div>
      {/* <button className="home_bottom_btn" onClick={() => window.open("/practice-signup","_blank")}>Practice Signup</button> */}
      {/* <div>
        <div className="home_bnnr home">
          <img src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_all_pages_img/home_bnnr_img.webp" />
        </div>
        <div className="home_block1 home">
          <img src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_all_pages_img/IWC_home_block2_img.webp" />
        </div>
        <div className="home_block2 home">
          <img src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_all_pages_img/IWC_home_block2_img.png" />
        </div>
        <div className='home_block3 home'>
        <img src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_all_pages_img/IWC_home_block2_img.png"/>
        </div>
        <div className="home_block4 home">
          <img src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_all_pages_img/IWC_home_block3_img.png" />
        </div>
        <div className='home_block5 home'>
        <img src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_all_pages_img/IWC_HOME_FOOTER.png"/>
        </div>
      </div> */}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snakbaropen}
        autoHideDuration={2000}
        message={snackBarMsg}
        key="bottomcenter"
      />
    </>
  );
}

export default Home;
