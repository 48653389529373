import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
export const Traininglist = createAsyncThunk("training/list", async (reqbody) => {
    if (reqbody == null && reqbody === undefined) {
        reqbody = {



            "source": "traning_list",
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "email": "",

            "count": false,



        }
    }
    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/traninglist", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

// API call for users-list
export const Traininglessonlist = createAsyncThunk("traininglession/list", async (reqbody) => {


    if (reqbody == null && reqbody === undefined) {
        reqbody = {
            "source": "lesson_list",
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {

            },
            "project": {},
            "token": "",
            "email": "",

            "count": false,

        }
    }

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/lessonlist", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


// API call for users-list

export const getTrainingData = createAsyncThunk("gettrainnigdata/list", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "traning_list",
        "sort": {
            "type": "desc",
            "field": "name"
        },
        "searchcondition": {

        }
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/autocomplete-traning", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});


/****************Change Status for training******************** */


// export const updateStatus = createAsyncThunk("training/statuschange", async (reqbody) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(reqbody)
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api1/statusupdate-traning", requestOptions);
//     const respdata = await response.json();
//     return respdata;

// })

/****************Change Status for lesson******************** */


// export const updateStatusLesson = createAsyncThunk("training/statuschange", async (reqbody) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(reqbody)
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api1/statusupdate-lesson", requestOptions);
//     const respdata = await response.json();
//     return respdata;

// })


export const editDataFetch = createAsyncThunk("training/editdata", async (reqbody) => {
    const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}api1/getedittdata-traning?_id=${reqbody}&source=${"traning_list"}`,
        // requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata", respdata)
    return respdata;

})

export const editDataFetchLesson = createAsyncThunk("lesson/editdata", async (reqbody) => {
    const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}api1/getedittdata-traning?_id=${reqbody}&source=${"lesson_list"}`,
        // requestOptions
    );
    const respdata = await response.json();
    // console.log("respdata", respdata)
    return respdata;

})


export const gettessiondataforcenter = createAsyncThunk("gettessiondataforcenter/list", async (reqbody) => {

    // console.log("reqbody in reducer", reqbody);
    if (reqbody == null && reqbody === undefined) {
        reqbody = {
            "source": "lesson_list",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "priority",
                "type": "desc"
            },
            "searchcondition": {
                "traning_status": 1,
                "traning_category": 1
            },
            "project": {},
            "token": "",
            "email": ""
        }
    }

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    // console.log("req_body==", req_body);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/lessonlist-by-catagory", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});



export const fetchSingleUserData = createAsyncThunk("fetch_single/editUser", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetchuserdata", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



export const fetchUserDonetrainingData = createAsyncThunk("fetchUserDonetrainingData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'searchcondition': { '_id': reqBody } })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/done-traning-list", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const fetchUserDoneAdvtrainingData = createAsyncThunk("fetchUserDoneAdvtrainingData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'searchcondition': { '_id': reqBody } })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/additional-done-traning-list", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/***********Fetch Single data************** */

export const fetchSingleData = createAsyncThunk("fetch/singledata", async (reqBody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody, "source": "traning_list" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetch-single-training-lesson", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const fetchSingleDataForLesson = createAsyncThunk("fetch/singledataLesson", async (reqBody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody, "source": "lesson_list" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetch-single-training-lesson", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const training = createSlice({
    name: 'training',
    initialState: {
        submitting: false,
        loading: false,
        submitted: false,
        resMessage: null,
        successmsg: null,
        success: false,
        failed: false,
        trainingdata: [],
        traininglessondata: [],
        gettrainnigdata: [],
        // statusChangeFlag: null,
        editDataLoading: false,
        editData: null,
        editDataLoadingLesson: false,
        editDataLesson: null,
        // statusChangeFlagLesson: null,
        lessondataforcenter: [],
        userTraingpercentageData: null,
        userDonelessondata: [],
        fetchSingleData: null,
        fetchSingleDataLesson: null,
        traningList: null,
        activeCategory: 1,
        activeLesson: false,
        firstdataOpenFlag: false,
        flagforcenter: false,
        testDataaset: null,
        userDoneaddlessondata:[]
    },
    reducers: {
        settestDataaset(state, action) {

            state.testDataaset = action.payload;
        },
        clearAllCenterData(state, action) {
            state.userDonelessondata = [];
            state.lessondataforcenter = [];
            state.gettrainnigdata = [];
            state.traininglessondata = [];
            state.userTraingpercentageData = null;
            state.activeLesson=false

        },

        resetflagforcenter(state, action) {
            state.flagforcenter = false;

        },
        setActiveLesson(state, action) {
            state.activeLesson = action.payload

        },
        setLessons(state, action) {
            state.traininglessondata = action.payload
        },
        setActiveCategory(state, action) {
            if (action.payload !== state.activeCategory) state.lessondataforcenter = []
            state.activeCategory = action.payload ? action.payload : 1
        },
        setdata(state, action) {
            state.traininglessondata = []

        },
        setInitialData(state, action) {
            state.loading = false;
            state.success = false;
            state.fetchSingleData = null;
        },

        setInitialDataLesson(state, action) {
            state.loading = false;
            state.success = false;
            state.fetchSingleDataLesson = null;
        },
        clearEditDataTraining(state, action) {
            // console.log("state from seteditdata==>", state, action);
            state.editData = null;
        },
        clearEditDataLesson(state, action) {
            // console.log("state from seteditdata==>", state, action);
            state.editDataLesson = null;
        },
        clearTrainingList(state, action) {
            state.trainingdata = [];
        },
        clearLessonList(state, action) {
            state.traininglessondata = [];
        },
        clearlessondataforcenter(state, action) {
            state.lessondataforcenter = [];
            state.activeLesson=false
        },
        clearfirstdataOpenFlag(state, action) {
            state.firstdataOpenFlag = false;
        },
        clearfirstopendata(state, action) {
            if (state.lessondataforcenter && state.lessondataforcenter.length > 0 && state.lessondataforcenter[0].isopen) {
                state.lessondataforcenter[0].isopen = false;
            }

        },
        openandcloseaction(state, action) {
            console.log("ytyty", action.payload);
            if (action.payload !== null) {
                if (state.lessondataforcenter && state.lessondataforcenter.length > 0) {
                    for (let i in state.lessondataforcenter) {
                        if (action.payload == state.lessondataforcenter[i]._id) {
                            state.lessondataforcenter[i].isopen = true;
                        } else {
                            state.lessondataforcenter[i].isopen = false;
                        }
                    }
                }
            }
        }



    },
    extraReducers: {

        [fetchUserDonetrainingData.rejected]: (state, action) => {
            // state.loading = false;
            // state.successAction = false;

        },
        [fetchUserDonetrainingData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            // state.loading = true;
        },
        [fetchUserDonetrainingData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === "success") {
                // console.log("percentage block");
                state.userDonelessondata = action.payload.results.res;

            }
        },
        [fetchUserDoneAdvtrainingData.rejected]: (state, action) => {
            // state.loading = false;
            // state.successAction = false;

        },
        [fetchUserDoneAdvtrainingData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            // state.loading = true;
        },
        [fetchUserDoneAdvtrainingData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === "success") {
                // console.log("percentage block");
                state.userDoneaddlessondata = action.payload.results.res;

            }
        },



        

        [fetchSingleUserData.rejected]: (state, action) => {
            // state.loading = false;
            // state.successAction = false;

        },
        [fetchSingleUserData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            // state.loading = true;
        },
        [fetchSingleUserData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === "successful") {
                // console.log("percentage block");
                state.userTraingpercentageData = action.payload.result.res[0];

            }
        },

        /// #################### Training Listing ###############################
        [Traininglist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [Traininglist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [Traininglist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                if (action.payload.page === 'Traning Center') state.traningList = action.payload.results.res;
                else {
                    state.trainingdata = action.payload.results.res;
                    state.message = action.payload.status;
                    state.loading = false;
                    state.tokenRequired = false;
                    state.tokenRequiredMsg = ''
                }
            }
        },

        //******************Update Status**************************** */

        // [updateStatus.pending]: (state, action) => {
        //     state.loading = true;
        //     state.success = false;
        //     state.statusChangeFlag = true;

        // },
        // [updateStatus.fulfilled]: (state, action) => {

        //     if (action.payload.status == 'success') {
        //         state.loading = false;
        //         //   state.success = true;
        //         state.submitting = false;
        //         state.statusChangeFlag = false;

        //     }
        // },
        // [updateStatus.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.statusChangeFlag = false;
        // },

        //******************Update Status Lesson**************************** */

        // [updateStatusLesson.pending]: (state, action) => {
        //     state.loading = true;
        //     state.success = false;
        //     state.statusChangeFlagLesson = true;

        // },
        // [updateStatusLesson.fulfilled]: (state, action) => {

        //     if (action.payload.status == 'success') {
        //         state.loading = false;
        //         //   state.success = true;
        //         state.submitting = false;
        //         state.statusChangeFlagLesson = false;

        //     }
        // },
        // [updateStatusLesson.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.statusChangeFlagLesson = false;
        // },


        /*****************Edit Data*************** */

        [editDataFetch.pending]: (state, action) => {
            state.editDataLoading = true;


        },
        [editDataFetch.fulfilled]: (state, action) => {
            state.editData = action.payload.results.res[0];
            state.editDataLoading = false;

        },
        [editDataFetch.rejected]: (state, action) => {

        },
        /*****************Edit Data lesson*************** */

        [editDataFetchLesson.pending]: (state, action) => {
            state.editDataLoadingLesson = true;


        },
        [editDataFetchLesson.fulfilled]: (state, action) => {
            state.editDataLesson = action.payload.results.res[0];
            state.editDataLoadingLesson = false;

        },
        [editDataFetchLesson.rejected]: (state, action) => {

        },


        /// #################### USER LISting ###############################
        [Traininglessonlist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [Traininglessonlist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [Traininglessonlist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.traininglessondata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },

        /*******************************Fetch Single Data******************** */

        [fetchSingleData.pending]: (state, action) => {
            state.loading = true
            state.fetchSingleData = null

        },
        [fetchSingleData.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            if (action.payload.status === "success") {
                state.fetchSingleData = action.payload?.results?.res[0];

            }
        },
        [fetchSingleData.rejected]: (state, action) => {
            state.loading = false
        },

        /*******************************Fetch Single Data lesson******************** */

        [fetchSingleDataForLesson.pending]: (state, action) => {
            state.loading = true
            state.fetchSingleDataLesson = null

        },
        [fetchSingleDataForLesson.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            if (action.payload.status === "success") {
                state.fetchSingleDataLesson = action.payload?.results?.res[0];

            }
        },
        [fetchSingleDataForLesson.rejected]: (state, action) => {
            state.loading = false
        },


        /// #################### USER LISting ###############################
        [getTrainingData.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [getTrainingData.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [getTrainingData.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {
                state.gettrainnigdata = action.payload.results.res;

            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },

        [gettessiondataforcenter.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [gettessiondataforcenter.pending]: (state, action) => {
            console.log("action p", action)
            // state.usersList = [];
            if (action && action.meta && action.meta.arg && action.meta.arg.condition !== undefined && action.meta.arg.condition.skip !== undefined && action.meta.arg.condition.skip == 0) {
                state.loading = true;

            }
        },
        [gettessiondataforcenter.fulfilled]: (state, action) => {
            let fflag = 0;
            if (action.payload.status === 'success' && action.payload.results.res.length >0) {
                console.log("pppoooppp 1" ,state.lessondataforcenter, action.payload.results.res.length);
                if (state.lessondataforcenter.length == 0 && action.payload.results?.res && action.payload.results?.res !== undefined && action.payload.results?.res.length > 0) {

                    state.flagforcenter = true;
                    console.log("pppoooppp 2");

                }






            
                if (state.lessondataforcenter && state.lessondataforcenter.length > 0) {
                    fflag = 1;
                }
                if (action.payload.results?.res && action.payload.results?.res !== undefined && action.payload.results?.res.length > 0) {

                    console.log("pppoooppp");

                    state.lessondataforcenter = [...state.lessondataforcenter, ...action.payload.results.res];
                    console.log("pppoooppp data", state.lessondataforcenter);
                    // let unique = [...new Map(datac.map(item =>
                    //     [item['_id'], item])).values()];
                    //     state.lessondataforcenter=unique
                    // state.lessondataforcenter =action.payload.results.res;
                }
                if (state.lessondataforcenter && state.lessondataforcenter.length > 0) {
                    // console.log("state.lessondataforcenter.length", state.lessondataforcenter.length)
                    console.log("pppoooppp 4");
                    if (fflag == 0) {
                        // console.log("inside flag");
                        state.lessondataforcenter[0]['isopen'] = true;
                        console.log("pppoooppp 7");
                    }
                    // state.lessondataforcenter[0]['firstvalue'] = true;
                    state.firstdataOpenFlag = true;
                    if (state.activeLesson === false) {
                        // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
                        console.log("pppoooppp 6");
                        state.activeLesson = state.lessondataforcenter[0]._id
                    }
                    // state.lessondataforcenter[0]['done']=2;
                }
                // if(state.lessondataforcenter.length>0){
                //     // let unique = [...new Set(state.lessondataforcenter.map(item => item._id))];
                //     let unique = [...new Map(state.lessondataforcenter.map(item =>
                //         [item['_id'], item])).values()];
                //     state.lessondataforcenter=[...unique]
                // }



                // setTimeout(() => {
                //     state.flagforcenter = false;
                // }, 2000);
                console.log("pppoooppp +-", state.lessondataforcenter);

                

            }
            state.loading = false;
            if (action.payload.results.res.length === 0) {
                // console.log("not present");
                state.loadmoreflag = false
                console.log("pppoooppp 3",state.lessondataforcenter);
            } else state.loadmoreflag = true
        },


    }
})

export default training.reducer;
export const { setInitialData, clearEditDataLesson, clearLessonList, clearlessondataforcenter, clearTrainingList, clearEditDataTraining, setActiveCategory, setActiveLesson, setLessons, clearfirstdataOpenFlag, clearfirstopendata, openandcloseaction, resetflagforcenter, clearAllCenterData, settestDataaset } = training.actions;