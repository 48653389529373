import { Box, Button, CircularProgress, Fab, FormHelperText, LinearProgress, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";
import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "./formcomp.css";
import "cropperjs/dist/cropper.css";
import store from "../../store";
import { resetFormFieldError, resetTempFormUpdateData } from "../formReducer";
import { useDispatch, useSelector } from "react-redux";
import post from 'axios';
import { deleteState, setInitialState, updateState, updateUploadURL, updateWholeState } from "../fileUploaderReducer";
import { cloneDeep } from 'lodash';
////////////////////////// Fetch base 64 string from url ///////////////////////////
const getBase64FromUrl = (url) => {
    return new Promise(async (resolve, reject) => {
        try {
            const data = await fetch(url, {
                method: "GET"
            });
            const blob = await data.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        } catch (error) {
            console.log('error')
            reject(url)
        }
    })
}

///////////////////////////////// Convert data url to file //////////////////////////
function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}
/////////////////////////////////////////////////////////////////////////

export const FileUploader = ({ fielddata, value, setValue, getValues, errors, clearErrors, setError, formId }) => {

    const [file, setFile] = useState([])
    const [error, setErrorFlag] = useState(false);
    const [flag, setFlag] = useState(null);
    const [cropData, setCropData] = useState({});
    const [cropper, setCropper] = useState({});
    const [disable, setDisable] = useState(false);
    const [flagAutoUpload, setFlagAutoUpload] = useState(false);
    const [customError, setCustomError] = useState(null);
    const [val, setVal] = useState(null);
    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch()

    const data = useSelector((state) => state.fileUploaderSlice.files[fielddata.name] || { fileName: [], uploadURL: {}, file: [], type: [] })

    useEffect(() => {
        // console.log('data22222222222222222222222', data)
    }, [data])
    useEffect(() => {
        // console.log('data22222222222222222222222', data)
    }, [])

    store.subscribe(() => {
        const storeData = store.getState();
        // console.log("storing====>", formId);
        // console.log("storing====>", fielddata.name);
        // console.log("storing====>", storeData);
        // console.log("storing externalSeachReset ====>", storeData.formSlice.externalSeachReset);
        // externalSeachReset
        if (storeData.formSlice !== undefined && storeData.formSlice.externalSeachReset !== undefined && storeData.formSlice.externalSeachReset[formId] !== undefined && storeData.formSlice.externalSeachReset[formId]['restform'] !== undefined && storeData.formSlice.externalSeachReset[formId]['restform'] === true && storeData.fileUploaderSlice !== undefined && storeData.fileUploaderSlice.files !== undefined && storeData.fileUploaderSlice.files[fielddata.name].file !== undefined && storeData.fileUploaderSlice.files[fielddata.name].file.length > 0) {
            // data.file = [];
            // console.log('reset file data', data.file.length, storeData.fileUploaderSlice);
            // setFile([]);
            // dispatch(deleteState({ fieldName: fielddata.name, index }))
            // document.getElementsByClassName('removeImage').click();


            dispatch(setInitialState({ fieldName: fielddata.name }))
            if (fielddata.multiple)
                setValue(fielddata.name, [])
            else
                setValue(fielddata.name, undefined)
        }

        if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {

            setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
        }

        //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
        if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
            // console.log("storing====>"); 
            setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
        }
    })



    ///////////////////////////////// To set by default an array //////////////////////////////
    useEffect(() => {
        dispatch(setInitialState({ fieldName: fielddata.name }))
        if (fielddata.multiple)
            setValue(fielddata.name, [])
        else
            setValue(fielddata.name, undefined)
    }, [])


    useEffect(() => {
        if (val !== null && Array.isArray(val) && val.length > 0) {
            val.forEach((curFile) => {
                const fileNameGenerate = (fielddata.prefix ? fielddata.prefix : "") + "_" + moment().unix() + "_" + curFile.name.trim().replaceAll(/\s/g, '')
                getBase64FromUrl(curFile.url).then((res) => {
                    if (res) console.log("file_data===>", res)

                    //////////////////////////////////////// File Size calculate ///////////////////////////////
                    const stringLength = res.length - `${res.split(",")[0]},`.length;
                    const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
                    const fileDetails = {
                        file_local_name: curFile.name,
                        file_size: sizeInBytes,
                        file_server_name: fileNameGenerate,
                        file_type: curFile.type,
                        file_data: res
                    }
                    dispatch(updateState({ fieldName: fielddata.name, fileDetails, type: curFile.type, name: curFile.name }))
                    ////////////////////////////////// For Different file upload method ///////////////////////////////
                    if (fielddata.uploadMethod && fielddata.uploadMethod.length > 0) {
                        const file = dataURLtoFile(res, curFile.name)
                        setFile((prev) => {
                            return [...prev, file]
                        })
                    }
                }).catch(url => {
                    const fileDetails = {
                        file_local_name: curFile.name,
                        file_server_name: fileNameGenerate,
                        file_type: curFile.type,
                        file_url: url
                    }
                    dispatch(updateState({ fieldName: fielddata.name, fileDetails, type: curFile.type, name: curFile.name }))

                    if (fielddata.multiple) {
                        setValue(fielddata.name, [...getValues(fielddata.name), url])
                    } else {
                        setValue(fielddata.name, url)
                    }
                })

            })
            dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
        }
    }, [val])

    /////////////////////////////////// For Edit and Auto Upload //////////////////////////////////////////////
    useEffect(() => {
        if (fielddata.defaultFileLinks && fielddata.defaultFileLinks.length > 0) {
            fielddata.defaultFileLinks.forEach((curFile) => {
                const fileNameGenerate = (fielddata.prefix ? fielddata.prefix : "") + "_" + moment().unix() + "_" + curFile.name.trim().replaceAll(/\s/g, '')
                console.log('fileNameGenerate===>', fileNameGenerate)
                getBase64FromUrl(curFile.url).then((res) => {
                    console.log("file_data===>", res)

                    //////////////////////////////////////// File Size calculate ///////////////////////////////
                    const stringLength = res.length - `${res.split(",")[0]},`.length;
                    const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
                    const fileDetails = {
                        file_local_name: curFile.name,
                        file_size: sizeInBytes,
                        file_server_name: fileNameGenerate,
                        file_type: curFile.type,
                        file_data: res
                    }
                    dispatch(updateState({ fieldName: fielddata.name, fileDetails, type: curFile.type, name: fileNameGenerate }))
                }).catch(url => {
                    const fileDetails = {
                        file_local_name: curFile.name,
                        file_server_name: fileNameGenerate,
                        file_type: curFile.type,
                        file_url: url
                    }

                    dispatch(updateState({ fieldName: fielddata.name, fileDetails, type: curFile.type, name: fileNameGenerate }))
                    if (fielddata.multiple) {
                        setValue(fielddata.name, [...getValues(fielddata.name), { url, file_server_name: "", file_local_name: "", file_size: "", file_crop_coord: "" }])
                    } else {
                        setValue(fielddata.name, { url, file_server_name: "", file_local_name: "", file_size: "", file_crop_coord: "" })
                    }
                })

            })
            setFlagAutoUpload(true)
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (flagAutoUpload == true && data.fileName.length != undefined && fielddata.defaultFileLinks.length != undefined && data.fileName.length == fielddata.defaultFileLinks.length) {
                console.log('daata======>', data)
                data.fileName.forEach((curFile, index) => {
                    if (!Object.keys(data.uploadURL).includes(curFile) && data.file[index].file_data) {
                        uploadImage(null, index)
                    }
                })
                setFlagAutoUpload(false)
            }
        }, 3000)
    }, [flagAutoUpload, data])
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////// Effect the field error from outside //////////////////////////////
    useEffect(() => {
        if (customError !== null) {
            setError(fielddata.name, customError);
            dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
        }
    }, [customError]);


    /////////////////////////////////// On file Change ////////////////////////////
    const onFileChange = (e) => {
        console.log("on file change", e)
        setErrorFlag(false)
        clearErrors(fielddata.name)
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        ////////////////////////////////// For Different file upload method ///////////////////////////////
        if (fielddata.uploadMethod && fielddata.uploadMethod.length > 0) {
            setFile((prev) => {
                return [...prev, files[0]]
            })
        }
        createImage(files[0])

    }

    ////////////////////////// After file change create image ////////////////////////
    const createImage = (file) => {
        // var image = new Image()
        let reader = new FileReader()
        reader.onload = (e) => {
            console.log('length: ', e.target.result)
            if (fielddata.maxFileSize && e.target.result.length > fielddata.maxFileSize) {
                return alert('File is loo large.')
            }
            console.log('file details===>', e.target.result, file);
            const fileNameGenerate = (fielddata.prefix ? fielddata.prefix : "") + "_" + moment().unix() + "_" + file.name.trim().replaceAll(/\s/g, '')
            const fileDetails = {
                file_local_name: file.name,
                file_size: file.size,
                file_server_name: fileNameGenerate,
                file_type: file.type,
                file_data: e.target.result
            }
            dispatch(updateState({ fieldName: fielddata.name, fileDetails, type: file.type, name: fileNameGenerate }))
        }
        reader.readAsDataURL(file)
    }

    //////////////////////////////// remove Image ///////////////////////////////////
    const removeImage = (e, index) => {

        console.log('Remove clicked', index)
        if (data.file[index].file_url) {
            if (fielddata.multiple) {
                const formUrls = [...getValues(fielddata.name)]
                const findUrlIndexInData = formUrls.findIndex((cur) => cur == data.file[index].file_url)
                formUrls.splice(findUrlIndexInData, 1)

                const findIndexInFile = file.findIndex((cur) => cur == data.file[index].file_url)
                const newFile = [...file];
                newFile.splice(findIndexInFile, 1)
                setFile(newFile)
                setValue(fielddata.name, formUrls)
            } else {
                setFile([])
                setValue(fielddata.name, undefined)
            }

        }
        dispatch(deleteState({ fieldName: fielddata.name, index }))


    }


    //////////////////////////////////// Fetch signed url and upload to bucket //////////////////////
    const uploadImage = async (e, index) => {
        console.log('data===>', data.file[index].file_data);
        setLoading(true);
        setDisable(true)
        if (fielddata.uploadMethod && fielddata.uploadMethod.length > 0) {

            const data = new FormData();
            data.append("file", file[index]);
            const url = `${fielddata.base_url}${fielddata.endpoint}`;
            const config = {
                method: "POST",
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            const response = await post(url, data, config);

            console.log('response===>', response)

            const oldValues = getValues(fielddata.name);
            const fileLink = response.data;

            console.log('oldValues===>', oldValues)
            if (response.status === 200) {
                const url = response.data;
                dispatch(updateUploadURL({ url, fieldName: fielddata.name, index }))
                setErrorFlag(false)
                if (fielddata.multiple) {
                    setValue(fielddata.name, [...oldValues, fileLink]);
                } else {
                    setValue(fielddata.name, fileLink);
                }
            } else {
                setErrorFlag(true)
            }


        } else {
            if (!fielddata.bucket || !fielddata.path || !fielddata.base_url || !fielddata.endpoint) return
            // Get the presigned URL
            /////////////////////////////////////////////////////////////////////////////////
            const reqBody = {
                "bucket": fielddata.bucket,
                "path": fielddata.path,
                "name": data.fileName[index],
                "type": data.type[index],
            }
            const requestOptions = {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(reqBody),
            };


            const response = await fetch(`${fielddata.base_url}${fielddata.endpoint}`, requestOptions)
            const res = await response.json();
            //////////////////////////////////////////////////////////////////////////////////

            // Upload Image in the bucket
            /////////////////////////////////////////////////////////////////////////////////
            console.log("data+++89898+", data);
            let binary = atob(data.file[index].file_data.split(',')[1])
            let array = []
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
            }
            let blobData = new Blob([new Uint8Array(array)], { type: data.type })
            const headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': data.type[index]
            }
            const uploadImgRes = await fetch(res.uploadURL, {
                method: "PUT",
                headers: headers,
                body: blobData
            })
            /////////////////////////////////////////////////////////////////////////////////
            console.log('uploadImage', uploadImgRes)

            if (uploadImgRes.status === 200) {
                const url = res.uploadURL.split('?')[0];
                const file_server_name = data.file[index].file_server_name
                const file_local_name = data.file[index].file_local_name
                const file_size = data.file[index].file_size
                const file_crop_coord = data.file[index].file_crop_coord || {}

                dispatch(updateUploadURL({ url, fieldName: fielddata.name, index }))
                setErrorFlag(false)
                if (fielddata.multiple) {
                    setValue(fielddata.name, [...getValues(fielddata.name), { url, file_server_name, file_local_name, file_size, file_crop_coord, type: data.type[index] }])
                } else {
                    setValue(fielddata.name, { url, file_server_name, file_local_name, file_size, file_crop_coord, type: data.type[index] })
                }


            } else {
                setErrorFlag(true)
            }
        }
        setDisable(false);
        setLoading(false);

    }

    /////////////////////////////////////////// For Delete ////////////////////////////////////////
    const deleteImageFromBucket = async (e, index) => {
        setLoading(true);
        setDisable(true)
        const reqBody = {
            file: data.fileName[index],
            path: fielddata.path,
            bucket: fielddata.bucket,
            secret: "Wj7ZnV!bS",
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTc3NzgxNDUsImlhdCI6MTY1NzY5MTc0NX0.KteCfRgGxxAkZAeFpzHGUDaED7wekhmlJCNaPd6aZs0"
        }
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(reqBody),
        };

        const response = await fetch(`${fielddata.base_url}${fielddata.deleteEndpoint}`, requestOptions)
        const res = await response.json();

        console.log('res====>', res);

        if (res.status === 'success') {
            console.log('index=====>', index, data)

            ////////////////////////////////////////// Delete file data from form value set /////////////////////////////
            if (fielddata.multiple) {
                const newFileData = [...getValues(fielddata.name)].filter((cur) => cur.file_server_name !== data.fileName[index])
                console.log('newFileData===>', newFileData)
                setValue(fielddata.name, newFileData)
            } else {
                setValue(fielddata.name, undefined)
            }

            ////////////////////////////////////////////////////////////////////////////////////////////////////////////
            dispatch(deleteState({ fieldName: fielddata.name, index }))

            if (e === null) setFlag(flag != null ? !flag : false)
        } else {
            setErrorFlag(true)
        }
        setDisable(false)
        setLoading(false);
    }
    /////////////////////////////// For Delete all ////////////////
    const deleteAll = (e) => {
        deleteImageFromBucket(null, 0)
    }
    useEffect(() => {
        if (data.file.length > 0 && flag !== null) {
            deleteImageFromBucket(null, 0)
        }
    }, [flag])

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        console.log('data======>', fielddata.name, data)
    }, [data])


    const selectIcon = (type) => {

        console.log('fileType', type)
        if (type === undefined) return ''

        let fileType;

        if (type.includes('document')) {
            fileType = 'doc'
        } else {
            fileType = type.split('/').slice(-1)[0];
        }
        return fileType
    }

    ///////////////////////////// For Upload All Files /////////////////////// 
    const uploadAll = (e) => {
        data.fileName.forEach((curFile, index) => {
            if (!Object.keys(data.uploadURL).includes(curFile)) {
                uploadImage(e, index)
            }
        })
    }




    //////////////////////////////// Image Crop ////////////////////////////
    const getCropData = (index) => {
        if (typeof cropper[data.fileName[index]] !== "undefined") {

            console.log("cropped info===>", cropper[data.fileName[index]].getData(), cropper[data.fileName[index]].getCroppedCanvas().toDataURL())
            //////////////////////////////////////////////////////////////////////////////////////////////////

            setCropData((prev) => {
                return {
                    ...prev,
                    [data.fileName[index]]: {
                        file_crop_coord: cropper[data.fileName[index]].getData(),
                        file_data: cropper[data.fileName[index]].getCroppedCanvas().toDataURL()
                    },
                }
            })
        };
    }

    useEffect(() => {
        console.log('cropData====>', cropData)
        //////////////////////////// Update Cropped File info //////////////////////////////
        if (Object.entries(cropData).length > 0) {
            Object.entries(cropData).forEach((curFile) => {
                const tempData = cloneDeep(data)
                const targetFile = tempData.file.find((cur) => cur.file_server_name == curFile[0])
                console.log("targetFile", targetFile, curFile)
                if (targetFile !== undefined) {
                    targetFile.file_crop_coord = curFile[1].file_crop_coord
                    dispatch(updateWholeState({ fieldName: fielddata.name, data: tempData }))
                    console.log("targetFile", targetFile.file_crop_coord === curFile[1].file_crop_coord, data)
                }
            })
        }
    }, [cropData])

    // useEffect(() => {
    //     console.log("uploading===>", uploading)
    //     if (Object.keys(uploading).length > 0) {
    //         setDisable(true)
    //     } else {
    //         setDisable(false)
    //     }
    // }, [uploading])

    return (
        <div className={`${fielddata.className} upload_data_wrpr`} >
            {/* <spn>dddd</spn> */}
            <div className="blog_img_wrpr">
                <div id="list">
                    {fielddata.heading && <h1>{fielddata.heading}</h1>}
                    {fielddata.description && <p className="file_description">{fielddata.description}</p>}
                </div>
                {(fielddata.multiple == true || (!fielddata.multiple && data.file.length < 1)) && <div className="upload-container">
                    <label htmlFor={fielddata.name}>
                        <input
                            style={{ display: 'none' }}
                            id={fielddata.name}
                            name="upload-photo"
                            type="file"
                            className="fileUploadInput"
                            onChange={onFileChange}
                            accept={fielddata.accept}
                        />
                        <Tooltip title={fielddata.label}>
                            <Fab color="primary" size="large" component="span">
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </label>
                </div>}
            </div>

            {/* {fielddata.name}  {getValues(fielddata.name)} m {data.file.length} */}
            {/* getValues(fielddata.name) !== null && getValues(fielddata.name) !== undefined && */}
            {data.file.length > 0 && data.file.map((curFile, index) => (<div className="upload-container" key={moment().unix() + index}>
                <div className="image_upload_container">
                    <div className="image_container">
                        {/* ------------------------------- For Image Upload ---------------------------------- */}
                        {data.type[index] !== undefined && data.type[index].includes('image') && (
                            <>
                                {!fielddata.uploadMethod && curFile.file_data && <div className="content_wrpr">
                                    <Cropper
                                        zoomTo={0.5}
                                        initialAspectRatio={1}
                                        preview=".img-preview"
                                        src={curFile.file_data}
                                        viewMode={1}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                        onInitialized={(instance) => {
                                            setCropper((prev) => {
                                                return {
                                                    ...prev,
                                                    [data.fileName[index]]: instance
                                                }
                                            });
                                        }}
                                        guides={true}
                                    />
                                    <h1>
                                        <Button className="cropimagebtn" variant="outlined" onClick={() => getCropData(index)}>
                                            Crop Image
                                        </Button>
                                    </h1>
                                </div>}
                                <div className="box" style={{}}>
                                    <div className="cropimagewrapper">
                                        {curFile.file_data && <img className="image_preview" src={cropData[data.fileName[index]] ? cropData[data.fileName[index]].file_data : curFile.file_data} alt="cropped" />}
                                        {curFile.file_url && <img className="image_preview" src={curFile.file_url} alt="cropped" />}
                                    </div>

                                    {/* -------------------------------- Foer File name view ----------------------------------------------------- */}
                                    <h3 className="image_name">{data.file[index].file_local_name}</h3>
                                </div>
                            </>
                        )}

                        {/* ------------------------------------------------- For Icon except the Image ---------------------------------- */}
                        {data.type[index] !== undefined && !data.type[index].includes('image') && <>
                            <div className="box" style={{}}>
                                <span
                                    className={`fileIcon ${selectIcon(data.type[index])}`}
                                ></span>
                                <h3 className="image_name">
                                    {data.file[index].file_local_name}
                                </h3>
                            </div>
                        </>}

                        {/* ------------------------------------------- For Audio Player ------------------------------------------------------ */}
                        {data.type[index] !== undefined && !data.type[index].includes('image') && data.type[index].includes('audio') && curFile.file_data && <audio controls src={curFile.file_data}>Your browser does not support the <code>audio</code> element.Here is a <a href={curFile.file_data}>link to the video</a> instead.</audio>}
                        {data.type[index] !== undefined && !data.type[index].includes('image') && data.type[index].includes('audio') && curFile.file_url && <audio controls src={curFile.file_url}>Your browser does not support the <code>audio</code> element.Here is a <a href={curFile.file_data}>link to the video</a> instead.</audio>}

                        {/* -------------------------------------------- For Video Player --------------------------------------------------- */}
                        {data.type[index] !== undefined && !data.type[index].includes('image') && data.type[index].includes('video') && curFile.file_data &&
                            <video controls preload="auto" width="300">
                                <source src={curFile.file_data} type="video/webm" />
                                <source src={curFile.file_data} type="video/mp4" />
                                <p>Your browser doesn't support HTML5 video. Here is a <a href={curFile.file_data}>link to the video</a> instead.</p>
                            </video>}
                        {data.type[index] !== undefined && !data.type[index].includes('image') && data.type[index].includes('video') && curFile.file_url &&
                            <video controls preload="auto" width="300">
                                <source src={curFile.file_data} type="video/webm" />
                                <source src={curFile.file_data} type="video/mp4" />
                                <p>Your browser doesn't support HTML5 video. Here is a <a href={curFile.file_data}>link to the video</a> instead.</p>
                            </video>}



                        {/* --------------------------------------- For Buttons  -------------------------------------------------- */}
                        <div className="btn_container">
                            {!fielddata.uploadMethod && data.uploadURL[data.fileName[index]] && <Button variant="contained" type="button" className="deleteFromBucket" disabled={disable} onClick={(e) => deleteImageFromBucket(e, index)}>Delete</Button>}
                            {!data.uploadURL[data.fileName[index]] && <Button variant="contained" type="button" className="removeImage" disabled={disable} onClick={(e) => removeImage(e, index)}> Remove</Button>}
                            {!data.uploadURL[data.fileName[index]] && curFile.file_data && <Button variant="contained" type="button" className="uploadImage" disabled={disable} onClick={(e) => uploadImage(e, index)}>Upload</Button>}
                        </div>
                        {/* <p>{JSON.stringify(loading,null,2)}</p> */}

                        {loading && (
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                            </Box>
                        )}
                    </div>
                </div>
            </div>)
            )}

            {/* ------------------------- Button Upload All --------------------------------- */}
            {data.file.length > 0 && (data.file.length !== Object.keys(data.uploadURL).length) && fielddata.multiple && <div>
                <Button variant="contained" type="button" className="uploadAll" disabled={disable} onClick={(e) => uploadAll(e)}>Upload All</Button>
            </div>}

            {/* ------------------------- Button Delete All --------------------------------- */}
            {
                !fielddata.uploadMethod && data.file.length > 0 && Object.keys(data.uploadURL).length > 0 && (data.file.length === Object.keys(data.uploadURL).length) && fielddata.multiple && <div>
                    <Button variant="contained" type="button" className="deleteAll" disabled={disable} onClick={(e) => deleteAll(e)}>Delete All</Button>
                </div>
            }

            {/* ------------------------ Messsage sucessfully uploaded --------------------------- */}
            {data.file.length > 0 && Object.keys(data.uploadURL).length > 0 && (data.file.length === Object.keys(data.uploadURL).length) && <h2>Success! All files uploaded to bucket.</h2>}
            {/* ------------------------- Message on Error ------------------------------------------- */}
            {error && <h2>Something Went Wrong</h2>}

            {/* ERROR */}
            {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}


        </div>
    )
}