import React from "react";
import { useNavigate } from "react-router-dom";
// import { Navigate } from 'react-router-dom'
import "../BottomFooterBlock/BottomFooterBlock.css";

function BottomFooterBlock() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bottom_common_block">
        <div className="common_width">
          <div className="bottom_block">
            <img
              src={`${process.env.REACT_APP_IMG_URL_IWC_HEADER}bottom_footer_abs_img_new.webp`}
            />
            <div className="bottom_innr_block">
              <h1>Get started with us!</h1>
              <div className="click_here_sec">
                <h3>
                  <a>
                    <button onClick={() => navigate("/")}>Click Here</button>
                  </a>
                </h3>
                <h4>
                  to schedule a meeting with our <br></br> knowledgeable
                  consulting team today!
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomFooterBlock;
