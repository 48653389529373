import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchUserData = createAsyncThunk("fetch/editUser", async (reqBody) => {
    console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    let req_body = {}
    if (reqBody.project) {
        req_body = { ...reqBody }
    } else {
        req_body = { _id: reqBody }
    }
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/fetchuserdata", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const fetchUserDataReducer = createSlice({
    name: 'fetchUserDataReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        fetchUserDataReducer: null
    },
    reducers: {
        setInitData(state, action) {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.successMsg = null;
            state.errorMsg = null;
            state.tokenRequired = false;
            state.tokenRequiredMsg = '';
            state.fetchUserDataReducer = null;
        }
    },
    extraReducers: {
        [fetchUserData.pending]: (state, action) => {
            state.loading = true
            state.fetchUserDataReducer = null

        },
        [fetchUserData.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            state.fetchUserDataReducer = action.payload?.result?.res[0];
        },
        [fetchUserData.rejected]: (state, action) => {
            state.loading = false
        },
    }
});



export default fetchUserDataReducer.reducer;
export const { setInitData } = fetchUserDataReducer.actions;