import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Paper } from '@mui/material'
import React from 'react'
import { randomId, timeConverter } from '../../helper/helperFunctions'

function ViewDialog({ open, close, data, buttonData }) {
  return (
    <Dialog open={open} onClose={() => close()} className='view_details'>
      <IconButton title='Close' className='close_icon_btn' onClick={close}><Icon>close</Icon></IconButton>

      <DialogTitle className='view_header previewModalTitle'>
        <div className='modal_title' dangerouslySetInnerHTML={{ __html: buttonData?.modalTitle ? buttonData.modalTitle : "Details" }}></div>
      </DialogTitle>
      <DialogContent className='content_container modalview_group'>
        {
          data?.map((eachData) => (
            <Paper key={() => randomId()} elevation={7} className="data_container">
              <div className="data_block">
                {
                  buttonData?.headers.map((header) => (
                    <div className='property_container'>
                      <label className='property_label' >{header.label ? header.label : "NA"} : </label>
                      <label className='property_value' dangerouslySetInnerHTML={{ __html: header.val && eachData[header.val] ? (header?.type === 'datetime' ? (timeConverter({ time: eachData[header.val], format: header.format ? header.format : "MM-DD-YYYY" })) : eachData[header.val]) : "NA" }}></label>
                    </div>
                  ))
                }
              </div>
            </Paper>
          ))
        }
      </DialogContent>
      <DialogActions className='action_btn_container'>
        <Button title={buttonData?.btn_label ? buttonData.btn_label : 'Close'} className='btn close_btn' onClick={close}>{buttonData?.btn_label ? buttonData.btn_label : 'Close'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewDialog