import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import ListingTable from "../../../listing/listing";

export const BookingList = ({ bookingListFetched }) => {

    const [bookingList, setBookingList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const bookingListObj = {
        tableId: "bookingLists",
        tableTitle: "Booking Status List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendarapi/booking-list",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendarapi/booking-list",
        },

        reqBody: {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["slot_start_time_unix", "booking_time_unix", "email", "type"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },

        searchBarData: {
            heading: "Search Booking Status List",
            api: {
                url: process.env.REACT_APP_API_URL,
                endPoint: "calendarapi/booking-list",
                tableCountEndpoint: "calendarapi/booking-list",
                reqBody: {
                    // count:true,
                    // "source": "users",
                    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
                    condition: {
                        limit: 5,
                        skip: 0,
                    },
                    sort: {
                        type: "desc",
                        field: "_id",
                    },
                },
            },

            searchSettings: {
                datesearch: [
                    {
                        id: 0,
                        heading: "Search By Appointment Start Time",
                        startdatelabel: "Start Date",
                        enddatelabel: "End Date",
                        className: "formGroup createdon_datetime countDiv__column--col4",
                        submit: "Search",
                        field: "createdon_datetime",
                    },
                ],
                selectsearch: [
                    {
                        id: 1,
                        label: "Search By Booking Status",
                        field: "booking_status",
                        type: "select",
                        className: "formGroup booking_status countDiv__column--col2",
                        values: ["Booking completed", "Sign-up completed"]
                    },
                    {
                        id: 2,
                        label: "Search By Type",
                        field: "type",
                        type: "select",
                        className: "formGroup type countDiv__column--col2",
                        values: ["Frontend", "NP"]
                    },


                ],
                textsearch: [
                    {
                        id: 3,
                        // heading: "Search By First Name",
                        label: "Search By Name ",
                        field: "fullname",
                        className: "formGroup name countDiv__column--col4",
                        value: "",
                    },
                    {
                        id: 4,
                        // heading: "Search By Email",
                        label: "Search By Email ",
                        field: "email",
                        className: "formGroup email countDiv__column--col6",
                        value: "",
                    },
                ],

            },
        },
    };

    var modifyTableHeaders =
        [
            { val: "booking_status", name: "Booking Status" },
            { val: "fullname", name: "Full Name" },
            // { val: "practicename", name: "Practice Name" },
            { val: "email", name: "Email" },
            { val: "slot_start_time_unix", name: "Appointment Start Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },
            { val: "booking_time_unix", name: "Booking Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },
            { val: "timespan", name: "Timespan" },
            { val: "type", name: "Type" },
        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(bookingListObj.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendarapi/booking-list",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setBookingList([...respdata.results.res])
        }
        setloaderFlag(false);
        bookingListFetched(true);
    }

    useEffect(() => {
        if (bookingList && bookingList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList()
        }

    }, [bookingList]);

    return (
        <>
            {loaderFlag == true ? (
                <Box sx={{ width: "100%", mt: "10px" }}>
                    <app-progressbar />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">

                {!loaderFlag && bookingList !== undefined ? (
                    <ListingTable
                        tableData={bookingListObj}
                        dataset={bookingList}
                        modifyHeaders={modifyTableHeaders}
                    />
                ) : ''}

            </div>
        </>
    )
}