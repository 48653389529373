import { Box, Button, LinearProgress, Snackbar } from '@mui/material';
import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from 'react';
// import './calender.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Cookies } from 'react-cookie';
import store from '../../store';
import { cancelEvent, cancelSubmissionStateReset, fetchEventWithIdentifier } from '../Calendar/calenderReducer';
import { timeConverter } from '../../helper/helperFunctions';

const CancelSlot = () => {
    const dispatch = useDispatch();
    const [loaderFlag, setloaderFlag] = useState(true);
    const [slotData, setSlotData] = useState(null);
    const [snakbaropen, setSnakbaropen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [cancelSubmissionState, setCancelSubmissionState] = useState(0);
    const [cancelData, setCancelData] = useState({});
    const [cancelslotFlag, setcancelslotFlag] = useState(true);

    const abbrs = {
        HST: 'Hawaii Standard Time',
        EST: 'Eastern Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
        IST: 'Indian Standard Time',
        AKDT: 'Alaska Standard Time'
    };


    let { identifier } = useParams();
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const navigate = useNavigate();
    console.log("params", identifier);
    store.subscribe(() => {
        const storeData = store.getState();
        setloaderFlag(storeData.calenderEventsStore.loading);
        // console.log("storeData.calenderEventsStore 888", storeData.calenderEventsStore)
        setSlotData(storeData.calenderEventsStore.fetchIdentifierData);
        setCancelData(storeData.calenderEventsStore.fetchIdentifierData);
        setCancelSubmissionState(storeData.calenderEventsStore.cancelSubmissionState);
        if (storeData.calenderEventsStore.fetchIdentifierData.canceled === true) {
            setcancelslotFlag(false);
        }
        if (storeData.calenderEventsStore.success && storeData.calenderEventsStore.successMsg !== "") {
            setSnakbaropen(true);
            setSnackBarMsg(storeData.calenderEventsStore.successMsg)
        }
        if (storeData.calenderEventsStore.error && storeData.calenderEventsStore.errorMsg !== "") {
            setSnakbaropen(true);
            setSnackBarMsg(storeData.calenderEventsStore.errorMsg)
        };
        // if (storeData.calenderEventsStore.fetchIdentifierData && Object.keys(storeData.calenderEventsStore.fetchIdentifierData).length > 0 && storeData.calenderEventsStore.fetchIdentifierData.condition) {
        //     // console.log("storeData.calenderEventsStore.fetchIdentifierData.condition.userid 666", storeData.calenderEventsStore.fetchIdentifierData.condition.userid)
        //     setCancelData(storeData.calenderEventsStore.fetchIdentifierData);
        // }
    })
    useEffect(() => {
        dispatch(fetchEventWithIdentifier({ identifier: identifier }))
    }, [])
    useEffect(() => {
        console.log("rescheduleSubmissionState before", cancelSubmissionState, cancelData);
        if (cancelSubmissionState === 2) {
            console.log("cancelSubmissionState");
            ///////////////////////// Clear Middleware cookies ////////////////////////
            //   const savedCookies = new Cookies();
            //   savedCookies.remove("middleware", { path: "/" });    

            dispatch(cancelSubmissionStateReset());
            const savedCookies = new Cookies();
            // savedCookies.remove("SlotData", { path: "/" });
            cookies.set('SlotData', cancelData, { path: '/' });
            navigate(`/thankyou/cancel/${cancelData._id}`, { replace: true });
            // resetFunction();
        }
    }, [cancelSubmissionState]);
    useEffect(() => {
        if (snakbaropen) {
            setTimeout(() => {
                setSnakbaropen(false);
            }, 3000)
        }
    }, [snakbaropen])

    const handleCancel = (confirm) => {
        const requestbody = {
            "identifier": identifier
        }
        // setLoaderFlag(true);
        if (confirm) {
            // console.log("identifier", identifier)
            dispatch(cancelEvent(requestbody));
        } else {
            console.log("don't cancel slot", slotData);
            // window.open(`/${slotData.locationPath}`, '_blank');
            navigate(`calendar/${slotData._id}`, { replace: true });
        }
        console.log("cancel slot", requestbody);
        // if (confirm) {
        // } else {
        //     console.log("don't cancel slot");
        // }
    }
    const handleClose = () => {

    }

    return (
        <>
            {/* {/ Meta Service Start Here /} */}
            {/* <MetaTags>
                <title>Cancel Slot</title>
                <meta
                    id="meta-description"
                    name="description"
                    content="One with Dawn for BioEnergetics, Michael for PECE, Integration meeting for PECE and another integration meeting for BioEnergetics."
                />
                <meta id="og-title" property="og:title" content="Available Slot" />
                <meta
                    id="og-image"
                    property="og:image"
                    content="path/to/og_image.jpg"
                />
            </MetaTags> */}
            {/* {/ Meta Service End Here /} */}

            {/* Loader */}
            {loaderFlag == true ? (
                <Box sx={{ width: "100%" }}>
                    <app-progressbar />
                </Box>
            ) : (
                ""
            )}
            {cancelslotFlag === true ?
                <div>
                    <div className="ContentWrapper calcelSlot_box">
                        <div className="Formdiv">
                            {/* {/ Login Form Start Here /} */}

                            <div className="FormBlock">
                                <div className="calender_body formCls darktheme">
                                    {slotData !== null && Object.keys(slotData).length > 0 ?
                                        <div className="calender_block" >

                                            <h2>Are you sure you want to cancel this appointment?</h2>
                                            <p>
                                                <strong>Event Title : </strong>{slotData.event_title}
                                            </p>
                                            {/* <p>
                                            <span>Description :</span>{slotData.description}
                                        </p> */}
                                            <p>
                                                <strong>Booking Date : </strong>{timeConverter({ time: slotData.slot_start_time_unix, format: "dddd MMMM Do YYYY", timezone: slotData.user_timezone })} <span>to</span> {timeConverter({ time: slotData.slot_end_time_unix, format: "dddd MMMM Do YYYY", timezone: slotData.user_timezone })}
                                            </p>
                                            <p>
                                                {/* <span>Event Time : </span>{timeConverter({ time: slotData.slot_start_time_unix, format: "h:mm:ss a" })} <span>to</span> {timeConverter({ time: slotData.slot_end_time_unix, format: "h:mm:ss a" })} */}
                                                <strong>Booking Time : </strong>{timeConverter({ time: slotData.slot_start_time_unix, format: "h:mm:ss a", timezone: slotData.user_timezone })} <span>to</span> {timeConverter({ time: slotData.slot_end_time_unix, format: "h:mm:ss a", timezone: slotData.user_timezone })}
                                            </p>
                                            <p>
                                                {/* <span>Timezone : </span> {slotData.timezoneName} */}
                                                <strong>Timezone : </strong> {abbrs[timeConverter({ time: new Date(slotData.slot_end_time_unix), format: "zz", timezone: slotData.user_timezone })]}
                                            </p>
                                            {slotData.atten_email.length > 0 && (
                                                <>
                                                    <p>
                                                        <strong>Attende Names : </strong>
                                                        {slotData.atten_email.map((cur, index) =>
                                                            <span className='innerSpan' key={index}>{cur.name}
                                                                {index === (slotData.atten_email.length - 1) ? '' : ', '}
                                                            </span>
                                                        )}
                                                    </p>
                                                    <p>
                                                        <strong>Attende Emails : </strong>
                                                        {slotData.atten_email.map((cur, index) =>
                                                            <span className='innerSpan' key={index}>
                                                                {cur.email}
                                                                {index === (slotData.atten_email.length - 1) ? '' : ', '}
                                                            </span>
                                                        )}
                                                    </p>
                                                </>
                                            )}

                                            <div className='cancelSlotBtnSection' >
                                                <Button variant='contained' onClick={() => handleCancel(true)}>Yes</Button>
                                                <Button variant='contained' onClick={() => handleCancel(false)}>No</Button>
                                            </div>
                                        </div>
                                        :

                                        <div className='noDataAvailable No_slot_div' >
                                            <img src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/no_data_found_logo.png" alt="" />
                                            No Appointment Found</div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <div className='thankyou-container canceledAlready darktheme'>
                    <div className='thankyou-block'>
                        <div className='content'>
                            <h1>Your booked Appointment has already been cancelled. </h1>
                        </div>
                    </div>
                </div>
            }
            <Snackbar
                // anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={3000}
                open={snakbaropen}
                onClose={handleClose}
                message={snackBarMsg}
                key='bottomcenter'
            />
        </>

    )
}

export default CancelSlot
