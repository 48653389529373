import * as React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Layout from "../Layout";
import Login from '../Pages/Login/Login';
import NoPage from '../Pages/404page';
import Home from '../Pages/Frontend/Home/Home';
import { ThankyouPage } from '../Pages/Frontend/Thankyou';
import CancelSlot from '../Pages/Frontend/CancelSlot';
import { ResetPassword } from '../Pages/ForgetPassword/ResetPassword';
import ForgotPassword from '../Pages/ForgetPassword/ForgotPassword';
import BottomFooterBlock from '../Pages/Frontend/BottomFooterBlock/BottomFooterBlock';
import { Miscellaneous } from '../Pages/Calendar/Miscellaneous/Miscellaneous';
import store from '../store';
import { BookedSlot } from '../Pages/Calendar/BookedSlot';





// ---- Back End Lazy Loading -----
// const Dashboard = React.lazy(() => import('../Pages/BackEndPages/Dashbaord/Dashbaord'))
// const Home = React.lazy(() => import('../Pages/Frontend/Home/Home'));
const Dashboard = React.lazy(() => import('../Pages/Backend/dashboard/dashboard'));
const AddTraining = React.lazy(() => import('../Pages/Backend/Training/TrainingCategory/AddTrainingCategory'));
const AddAdditionalTraining = React.lazy(() => import('../Pages/Backend/AdditionalTraining/AdditionalTrainingCategory/AddAdditionalTrainingCategory'));
const AddLesson = React.lazy(() => import('../Pages/Backend/Training/TrainingLesson/AddTrainingLesson'));
const AddAdditionalLesson = React.lazy(() => import('../Pages/Backend/AdditionalTraining/AdditionalTrainingLesson/AddAdditionalTrainingLesson'));
const TrainingList = React.lazy(() => import('../Pages/Backend/Training/TrainingCategory/TrainingCategoryList'));
const AddtionalTrainingList = React.lazy(() => import('../Pages/Backend/AdditionalTraining/AdditionalTrainingCategory/AdditionalTrainingCategoryList'));
const LessonList = React.lazy(() => import('../Pages/Backend/Training/TrainingLesson/TrainingLessonList'));
const AdditionalLessonList = React.lazy(() => import('../Pages/Backend/AdditionalTraining/AdditionalTrainingLesson/AdditionalTrainingLessonList'));
const UserManagement = React.lazy(() => import('../Pages/Backend/userManagement/userListing/userListing'));
const AddEdituser = React.lazy(() => import('../Pages/Backend/userManagement/userAddEdit/userAddEdit'));
const LeadListing = React.lazy(() => import('../Pages/Backend/userManagement/userListing/LeadListing'));
const AvailableSlots = React.lazy(() => import('../Pages/Calendar/AvailableSlot'));
// const Miscellaneous = React.lazy(() => import('../Pages/Calendar/Miscellaneous/Miscellaneous'));
// const BookedSlot = React.lazy(() => import('../Pages/Calendar/BookedSlot'));
const CreateEvent = React.lazy(() => import('../Pages/Calendar/CreateEvent'));
const AvaibilityList = React.lazy(() => import('../Pages/Calendar/AvaibilityList'));
const Calendar = React.lazy(() => import('../Pages/Frontend/Calendar'));
const Profile = React.lazy(() => import('../Pages/Backend/profile/profile'));
const Trainingcenter = React.lazy(() => import('../Pages/Backend/Training/TrainingCenter/TrainingCenter'));
const Programs = React.lazy(() => import('../Pages/Frontend/Programs/Programs'));
const Trainings = React.lazy(() => import('../Pages/Frontend/Trainings/Trainings'));
const ClientAcquisition = React.lazy(() => import('../Pages/Frontend/ClientAcquisition/ClientAcquisition'));
const ContactUs = React.lazy(() => import('../Pages/Frontend/ContactUs/ContactUs'));
const Dermacyte = React.lazy(() => import('../Pages/Frontend/Dermacyte/Dermacyte'));
const XWRAP = React.lazy(() => import('../Pages/Frontend/XWRAP/XWRAP'));
const AdditionalTrainingCenter = React.lazy(() => import('../Pages/Backend/Training/AdditionalTrainingCenter/AdditionalTrainingCenter'));
const CategoryAddEdit = React.lazy(() => import("../Pages/Backend/CategoryManagement/CategoryAddEdit"));
const CategoryList = React.lazy(() => import("../Pages/Backend/CategoryManagement/CategoryList"));
const CPTICDAddEdit = React.lazy(() => import("../Pages/Backend/CPTICDManagement/CPTICDAddEdit"));
const CPTICDList = React.lazy(() => import("../Pages/Backend/CPTICDManagement/CPTICDList"));
const PracticeSetupForm = React.lazy(() => import("../Pages/Frontend/ContractAssignment/PracticeSetupForm"));
const PracticeSignature = React.lazy(() => import("../Pages/Frontend/ContractAssignment/PracticeSignature"));
const FullFillAgreement = React.lazy(() => import("../Pages/Frontend/ContractAssignment/FullFillAgreement"));


{/* <-----------------------Backend Navigate Pages Routes Lazy Loading----------------------> */}

const CptCode = React.lazy(() => import('../Pages/Backend/BackendNavigatePages/CptCode'));

{/* <-----------------------Backend Navigate Pages Routes Lazy Loading----------------------> */}



// -----------------------


// ---- Front End Lazy Loading -----
// const Home = React.lazy(() => import('../Pages/FrontEndPages/Home'))
// -------------------------------------

const useAuth = () => {
  // console.log("AUTHED++++++++++", store.getState().loggedinuser.isLoggedIn)
  if (store.getState().loginSlice.isLoggedIn || (store.getState().loginSlice?.userInfo && Object.keys(store.getState().loginSlice.userInfo).length > 0)) {
    return true;
  }
}

const PrivateRoute = ({ children }) => {
  console.log("authed", useAuth());
  const auth = useAuth();
  return (
    auth === true ? children : <Navigate to="/login" replace={true} />
  )
}

// const LoginRoute = ({ children, redirectPath }) => {
//   const auth = useAuth();
//   return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
// }





// const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
// console.warn("<<< userinfocookies goes here >>>>", userInfo.userinfocookies)

const GetRoutes = () => {

  // React.useEffect(()=>{
  //   window.scrollTo({ top: 0, behavior: 'smooth' })

  // },[])
  return (
    <BrowserRouter>
      <Routes>

        {/* --------------------Front-End Pages-------------------- */}

        {/* <Route path="/home" element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "} */}


        {/* <Route path="/" index element={<Home />} />{" "} */}
        <Route path="login" element={<Login />} />{" "}





        {/* ----- Backend Pages ------ */}
        <Route path="/" element={<Layout />}>
          <Route path="/" index element={<Home />} />{" "}

          {/* <Route path="/login" element={<Suspense fallback={<></>}><Login /></Suspense>} />{" "} */}

          {/* <Route path="/" element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "} */}


          <Route path="/profile" element={<PrivateRoute><Suspense fallback={<></>}><Profile /></Suspense></PrivateRoute>} />{" "}
          <Route path="/dashboard" element={<PrivateRoute><Suspense fallback={<></>}><Dashboard /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-training" element={<PrivateRoute><Suspense fallback={<></>}><AddTraining /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-training/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddTraining /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-additional-training" element={<PrivateRoute><Suspense fallback={<></>}><AddAdditionalTraining /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-additional-training/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddAdditionalTraining /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-lesson" element={<PrivateRoute><Suspense fallback={<></>}><AddLesson /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-lesson/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddLesson /></Suspense></PrivateRoute>} />{" "}
          <Route path="/add-additional-lesson" element={<PrivateRoute><Suspense fallback={<></>}><AddAdditionalLesson /></Suspense></PrivateRoute>} />{" "}
          <Route path="/edit-additional-lesson/:_id" element={<PrivateRoute><Suspense fallback={<></>}><AddAdditionalLesson /></Suspense></PrivateRoute>} />{" "}
          <Route path="/training-category/list" element={<PrivateRoute><Suspense fallback={<></>}><TrainingList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/additional-training-category/list" element={<PrivateRoute><Suspense fallback={<></>}><AddtionalTrainingList /></Suspense></PrivateRoute>} />{" "}

          <Route path="/training-lesson/list" element={<PrivateRoute><Suspense fallback={<></>}><LessonList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/additional-training-lesson/list" element={<PrivateRoute><Suspense fallback={<></>}><AdditionalLessonList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/usermanagement" element={<PrivateRoute><Suspense fallback={<></>}><UserManagement /></Suspense></PrivateRoute>} />{" "}
          <Route path="/leads" element={<PrivateRoute><Suspense fallback={<></>}><LeadListing /></Suspense></PrivateRoute>} />{" "}

          <Route path="/available-slots" element={<PrivateRoute><Suspense fallback={<></>}><AvailableSlots /></Suspense></PrivateRoute>} />
          <Route path="/create-event" element={<PrivateRoute><Suspense fallback={<></>}><CreateEvent /></Suspense></PrivateRoute>} />
          <Route path="/edit-event/:slotID" element={<PrivateRoute><Suspense fallback={<></>}><CreateEvent /></Suspense></PrivateRoute>} />
          <Route path="/avilibility-list" element={<PrivateRoute><Suspense fallback={<></>}><AvaibilityList /></Suspense></PrivateRoute>} />
          <Route path="/calendar/:lead_id" element={<Suspense fallback={<></>}><Calendar /></Suspense>} />{" "}
          <Route path="/calendar-backend/:lead_id" element={<Suspense fallback={<></>}><Calendar type="np" /></Suspense>} />{" "}
          <Route path='/thankyou/:page/:lead_id' element={<Suspense fallback={<></>}><ThankyouPage /> </Suspense>} />{" "}
          <Route path="/cancel-slot/:identifier" element={<Suspense fallback={<></>}><CancelSlot /></Suspense>} />
          <Route path="/miscellaneous" element={<PrivateRoute><Suspense fallback={<></>}><Miscellaneous /></Suspense></PrivateRoute>} />{" "}
          <Route path="/booked-slot" element={<PrivateRoute><Suspense fallback={<></>}><BookedSlot /></Suspense></PrivateRoute>} />{" "}

          <Route path="/training-center" element={<PrivateRoute><Suspense fallback={<></>}><Trainingcenter /></Suspense></PrivateRoute>} />{" "}
          <Route path="/training-center/:cat_id" element={<PrivateRoute> <Suspense fallback={<></>}><Trainingcenter /></Suspense></PrivateRoute>} />{" "}
          <Route path="/training-center/:cat_id/:lesson_id" element={<PrivateRoute> <Suspense fallback={<></>}><Trainingcenter /></Suspense></PrivateRoute>} />{" "}
          <Route path="/programs" element={<Suspense fallback={<></>}><Programs /></Suspense>} />
          <Route path="/reset-password" element={<Suspense fallback={<></>}><ResetPassword /></Suspense>} />{" "}
          <Route path="/forget-password" element={<Suspense fallback={<></>}><ForgotPassword /></Suspense>} />{" "}
          <Route path="/trainings" element={<Suspense fallback={<></>}><Trainings /></Suspense>} />{" "}
          <Route path="/client-acquisition" element={<Suspense fallback={<></>}><ClientAcquisition /></Suspense>} />{" "}
          <Route path="/contact-us" element={<Suspense fallback={<></>}><ContactUs /></Suspense>} />{" "}
          <Route path="/dermacyte" element={<Suspense fallback={<></>}><Dermacyte /></Suspense>} />{" "}
          <Route path="/xwrap" element={<Suspense fallback={<></>}><XWRAP /></Suspense>} />{" "}
          <Route path="/bottom-footer-block" element={<Suspense fallback={<></>}><BottomFooterBlock /></Suspense>} />{" "}
          <Route path="/additional-training-center" element={<PrivateRoute> <Suspense fallback={<></>}><AdditionalTrainingCenter /></Suspense></PrivateRoute>} />{" "}
          <Route path="/additional-training-center/:cat_id/:lesson_id" element={<PrivateRoute> <Suspense fallback={<></>}><AdditionalTrainingCenter /></Suspense></PrivateRoute>} />{" "}
          <Route path="/additional-training-center/:cat_id" element={<PrivateRoute> <Suspense fallback={<></>}><AdditionalTrainingCenter /></Suspense></PrivateRoute>} />{" "}

          <Route path="/category-add" element={<PrivateRoute> <Suspense fallback={<></>}><CategoryAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/category-edit/:_id" element={<PrivateRoute> <Suspense fallback={<></>}><CategoryAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/category" element={<PrivateRoute> <Suspense fallback={<></>}><CategoryList /></Suspense></PrivateRoute>} />{" "}
          <Route path="/cpticd-add" element={<PrivateRoute> <Suspense fallback={<></>}><CPTICDAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/cpticd-edit/:_id" element={<PrivateRoute> <Suspense fallback={<></>}><CPTICDAddEdit /></Suspense></PrivateRoute>} />{" "}
          <Route path="/cpticd" element={<PrivateRoute> <Suspense fallback={<></>}><CPTICDList /></Suspense></PrivateRoute>} />{" "}
          {/* <-----------------------Backend Navigate Pages Routes----------------------> */}
          <Route path="/cpt-codes" element={<PrivateRoute><Suspense fallback={<></>}><CptCode /></Suspense></PrivateRoute>} />{" "}
          <Route path="/cpt-codes/:_id" element={<PrivateRoute><Suspense fallback={<></>}><CptCode /></Suspense></PrivateRoute>} />{" "}
          
          <Route path="/practice-signup" element={<Suspense fallback={<></>}><PracticeSetupForm /></Suspense>} />{" "}
          <Route path="/contract-signup1/:_id" element={<Suspense fallback={<></>}><PracticeSignature /></Suspense>} />{" "}

          {/* /--------------FullFillAgreement-----------------/ */}
          <Route path="/contract-signup2/:_id" element={<Suspense fallback={<></>}><FullFillAgreement /></Suspense>} />{" "}
          {/* <-----------------------Backend Navigate Pages Routes----------------------> */}


          {/* --- AddEdit Admin ---- */}
          <Route path="addedit-admin" element={<PrivateRoute><Suspense fallback={<></>}>
            <AddEdituser
              userType="admin"
              formType="Admin"
            // feilds={feildFirst}
            /></Suspense></PrivateRoute>} />{" "}

          {/* --- AddEdit organiser ---- */}
          <Route path="addedit-organiser" element={<Suspense fallback={<></>}>
            <AddEdituser
              userType="organiser"
              formType="Organiser"
            // feilds={feildFirst}
            /></Suspense>} />{" "}


          {/* --- AddEdit Practice ---- */}
          <Route path="addedit-practice" element={<Suspense fallback={<></>}>
            <AddEdituser
              userType="practice"
              formType="Practice"
            // feilds={feildFirst}
            /></Suspense>} />{" "}


          {/* --- AddEdit User ---- */}
          <Route path="addedit-user" element={<Suspense fallback={<></>}>
            <AddEdituser
              userType="user"
              formType="User"
            // feilds={feildFirst}
            /></Suspense>} />{" "}


          {/* --- No Page --- */}

          <Route path="/404page" element={<NoPage />} />{" "}
        </Route>
      </Routes>
    </BrowserRouter >
  );

}

export default GetRoutes;