import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import ListingTable from "../../../listing/listing"

export const ConflictingSlots = ({ conflictingSlotFetched }) => {

    const [conflictingSlotList, setConflictingSlotList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const conflictingList = {
        tableId: "conflictingSlotLists",
        tableTitle: "Conflicting Slots",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendarapi/conflicting-slots-listing",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendarapi/conflicting-slots-listing",
        },

        reqBody: {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["time_diff_creation", "slot_start_time_unix", "slot_end_time_unix"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },

        searchBarData: {
            heading: "Search Conflicting Slots",
            api: {
                url: process.env.REACT_APP_API_URL,
                endPoint: "calendarapi/conflicting-slots-listing",
                tableCountEndpoint: "calendarapi/conflicting-slots-listing",
                reqBody: {
                    // count:true,
                    // "source": "users",
                    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
                    condition: {
                        limit: 5,
                        skip: 0,
                    },
                    sort: {
                        type: "desc",
                        field: "_id",
                    },
                },
            },

            searchSettings: {
                datesearch: [
                    // {
                    //     id: 0,
                    //     heading: "Search By Joining Date",
                    //     startdatelabel: "Start Date",
                    //     enddatelabel: "End Date",
                    //     className: "formGroup createdon_datetime countDiv__column--col4",
                    //     submit: "Search",
                    //     field: "slot_start_time_unix",
                    // },
                ],
                selectsearch: [
                    // {
                    //     id: 1,
                    //     label: "Search By Status",
                    //     field: "status",
                    //     type: "select",
                    //     values: [0, 1],
                    //     // value: "",
                    // },


                ],
                textsearch: [
                    {
                        id: 1,
                        // heading: "Search By First Name",
                        label: "Search By Summary ",
                        field: "summary",
                        className: "formGroup summary countDiv__column--col4",
                        value: "",
                    },
                    {
                        id: 2,
                        // heading: "Search By First Name",
                        label: "Search By Connected Gmail ",
                        field: "connected_gmail",
                        className: "formGroup connected_gmail countDiv__column--col4",
                        value: "",
                    },
                    {
                        id: 3,
                        // heading: "Search By First Name",
                        label: "Search By User Email ",
                        field: "user_email",
                        className: "formGroup user_email countDiv__column--col4",
                        value: "",
                    },
                    // {
                    //     id: 4,
                    //     // heading: "Search By First Name",
                    //     label: "Search By Organizer Email ",
                    //     field: "organizer_email",
                    //     className: "formGroup organizer_email countDiv__column--col4",
                    //     value: "",
                    // },
                ],

            },
        },
    };

    var modifyTableHeaders =
        [
            { val: "summary", name: "Summary" },
            { val: "connected_gmail", name: "Connected Gmail" },
            { val: "user_email", name: "User Email" },
            { val: "time_diff_creation", name: "Deletion Time(min)" },
            { val: "timespan", name: "Timespan" },
            { val: "slot_start_time_unix", name: "Appointment Start Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },
            { val: "slot_end_time_unix", name: "Appointment End Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(conflictingList.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendarapi/conflicting-slots-listing",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setConflictingSlotList([...respdata.results.res])
        }
        setloaderFlag(false);
        conflictingSlotFetched(true);
    }

    useEffect(() => {
        if (conflictingSlotList && conflictingSlotList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList()
        }

    }, [conflictingSlotList]);

    return (
        <>
            {loaderFlag == true ? (
                <Box sx={{ width: "100%", mt: "10px" }}>
                    <app-progressbar />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">

                {!loaderFlag && conflictingSlotList !== undefined ? (
                    <ListingTable
                        tableData={conflictingList}
                        dataset={conflictingSlotList}
                        modifyHeaders={modifyTableHeaders}
                    />
                ) : ''}

            </div>
        </>
    )
}