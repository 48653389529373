import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const CategoryListFetch = createAsyncThunk("categoryList/fetch", async (reqBody) => {
    if (reqBody == null && reqBody === undefined) {
        reqBody = {
            "source": "category_list",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "field": "createdon_datetime",
                "type": "desc"
            },
            "searchcondition": {},
            "project": {},
            "token": "",
            "count": false
        }
    }

    // console.log("reqBody==>", reqBody);
    let req_body = {};
    req_body = { ...reqBody };
    // console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-cpt-icd/category-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
})

export const fetchSingleCategory = createAsyncThunk("fetch/singleCategory", async (reqBody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( reqBody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "manage-cpt-icd/category-list-count", reqOption);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const CategoryReducer = createSlice({
    name: "categoryReducer",
    initialState: {
        loading: false,
        success: false,
        error: false,
        message: null,
        categoryList: [],
        fetchSingleCategory: null,
    },
    reducers: {
clearEditDataCategory(state, action) {
            state.fetchSingleCategory = null;
        },
},
    extraReducers: {

        [CategoryListFetch.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [CategoryListFetch.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.categoryList = action.payload.results.res;
                state.loading = false;
            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },
        [CategoryListFetch.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchSingleCategory.pending]: (state, action) => {
            state.loading = true
            state.fetchSingleCategory = null

        },
        [fetchSingleCategory.fulfilled]: (state, action) => {
            console.log("dash action dataEdit", action)
            state.loading = false;
            state.success = true;
            if (action.payload.status === "success") {
                state.fetchSingleCategory = action.payload?.results?.res[0];

            }
        },
        [fetchSingleCategory.rejected]: (state, action) => {
            state.loading = false
        },
    }
});

export default CategoryReducer.reducer;
export const { clearEditDataCategory } = CategoryReducer.actions;