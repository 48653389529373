import { Button, Snackbar, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { Link, NavLink, useLocation, useMatch, useNavigate } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import { withCookies, Cookies, useCookies } from "react-cookie";

import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HomeIcon from "@mui/icons-material/Home";
import "./Layout.css";
import { logOut, setloginRoute, userLogout } from '../Login/loginReducer';
import store from '../../store';

import { gettessiondataforcenter, Traininglessonlist, Traininglist } from '../Backend/Training/TrainingReducer';
import { setLoader, setResloved } from './layoutReducer';
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import { resolvefunction } from '../../helper/helperFunctions';
import { Box } from '@mui/system';
import { fetchEventsData } from '../Calendar/calenderReducer';
import { gettraingcatagorydata } from '../Backend/dashboard/dashboardReducer';
import { getusersList } from '../Backend/userManagement/userListing/userListingReducer';
import CallIcon from "@mui/icons-material/Call";



import DehazeIcon from "@mui/icons-material/Dehaze";
import { AdditionalTrainingCatList, AdditionalTrainingLessList, getdataforadditionalcenter } from "../Backend/AdditionalTraining/AdditionalTrainingReducer";
import { CategoryListFetch } from "../Backend/CategoryManagement/CategoryReducer";
import { CPTICDListFetch } from "../Backend/CPTICDManagement/CPTICDReducer";

export default function BackendHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [selectMenu, setselectMenu] = React.useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const [logoutClick, setLogoutClick] = useState(false);
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const open4 = Boolean(anchorEl4);
  const handleClose4 = () => {
    setAnchorEl4(null);
    // resolveToPage('/training-category/list',[gettessiondataforcenter])

  };

  const isLoggedIn = useSelector((state) => {
    if (state.loginSlice.isLoggedIn !== null)
      return state.loginSlice.isLoggedIn;
    else return null;
  });

  const navigationItems = useRef([
    {
      title: "Dashboard",
      path: "admin/dashboard",
      resolveFunc: [gettessiondataforcenter],
    },
  ]);
  const [useManagementBody, setUseMAnagementBody] = useState({
    "source": "users",
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "field": "_id",
      "type": "desc"
    },
    "searchcondition": {
      // "status": 1,
      "practice_id": userInfo?.userInfo?.type == "practice" ? userInfo?.userInfo?._id : undefined, //-- list will show exculding this refrence user

    },
    "count": false,

  })

  const [eventListBody, setEventListBody] = useState({
    "condition": {
      "limit": 10,
      "skip": 0
    },
    "sort": {
      "field": "start_date_unix",
      "type": "desc"
    },
    "searchcondition": {
      "userid": userInfo?.userInfo?._id,
      // "start_date_unix": {
      //     "$gte": dayAfterTomorrow
      // },
      // "end_date_unix": {
      //     "$lte": 1675146756000
      // }
    },
    count: false
  })

  const [isActive, setActive] = useState("false");
  const resolveToPage = async (path, resolveFunc) => {
    // console.log("respolvehit", resolveFunc);
    setActive("false");
    // console.log("await-----------000", path, resolveFunc);
    // console.log("await-----------000", resolveFunc);
    // const alreadyResolved = store.getState().layoutSlice.resolved[path]
    try {
      if (!resolveFunc) {
        // if already resolved
        navigate(path); // navigating on already resolved
        return;
      }
      // console.log("Resolving========>", resolveFunc.length);
      dispatch(setLoader({ loader: true })); // Spinning Loader
      if (Array.isArray(resolveFunc) && resolveFunc.length > 1) {
        // console.log("await-----------11");
        resolveFunc.forEach(async (func) => await dispatch(func())); // for multiple api calls
        // console.log("await-----------after11");
      } else if (Array.isArray(resolveFunc) && resolveFunc.length == 1) {
        // console.log("await-----------22");
        await dispatch(resolveFunc[0]()); // resolveFunc is the asyncthunk for fetching initial data
        // console.log("await-----------after222");
      }

      dispatch(setResloved({ path, resolve: true })); // Setting store as resolved page
      dispatch(setLoader()); // Stopping Loader
      // console.log("await-----------after333");
      navigate(path); // Navigating to the path
    } catch (error) { }
  };

  console.warn("<<< userinfocookies goes here >>>>", userInfo)

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorCategory, setAnchorCategory] = React.useState(null);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const open3 = Boolean(anchorEl3);
  const openCategory = Boolean(anchorCategory);

  const openCategoryMenu = (event) => {
    setAnchorCategory(event.currentTarget);
  };
  const closeCategoryMenu = (event) => {
    setAnchorCategory(null);
  };
  const handleClick = (val, event) => {
    // console.log("val==>", val);
    // console.log("event==>", event);
    setselectMenu(val);
    // console.log('event.currentTarget==>', event.currentTarget);
    // console.log('All==>', event.currentTarget, open, open1, anchorEl, selectMenu, val);
    setAnchorEl(event.currentTarget);
    // console.log('All after==>', anchorEl, selectMenu, val);
  };
  const handleClose = () => {
    // console.log('before close ..', open, open1, anchorEl, selectMenu)
    setAnchorEl(null);
    setAnchorE2(null);
  };
  const clickedLogout = () => {
    let data = {
      id: userInfo?.userInfo?._id,
    };
    // dispatch(userLogout(data));
    setLogoutClick(true);
    setAnchorEl(null);
    // navigate("/");
    cookies.remove("userInfo", { path: "/" });
    cookies.remove("lastLoginTime", { path: "/" });
    cookies.remove("token", { path: "/" });
    cookies.remove("frontendUserInfo", { path: "/" });
    cookies.remove("backendUserInfo", { path: "/" });
    cookies.remove("practiceContactData", { path: "/" });

    dispatch(logOut());
    dispatch(setloginRoute(false));
    resolvefunction(navigate, '/', [userLogout(data)])
  };
  // useEffect(() => {
  //   if (isLogout) {
  //     dispatch(logOut());
  //     dispatch(setloginRoute(false));
  //   }
  // }, [isLogout]);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const categoryEditPath = useMatch("/category-edit/:_id");
  const cptCodesEditPath = useMatch("/cpticd-edit/:_id");
  // console.log("location ====", userInfo);

  const handleToggle = (temp) => {
    if (temp == "HeaderMenuToggle") setActive(!isActive);
  };

  //   useEffect(() => {
  //     if (logoutSuccess) {
  // console.log("isLoggedIn",isLoggedIn)
  //       setSnakbaropen(true);
  //       setSnackBarMsg("Logged Out Successfully");
  //       // cookies.remove("userInfo", { path: "/" });
  //       // cookies.remove("lastLoginTime", { path: "/" });
  //       // cookies.remove("token", { path: "/" });
  //       setIsLogout(true);
  //       setLogoutSuccess(false);
  //       setLogoutClick(false);
  //       setTimeout(() => {
  //         // navigate("/login");
  //       }, 800);
  //     }
  //   }, [logoutSuccess]);

  // store.subscribe(() => {
  // const storeData = store.getState();
  // if ( logoutClick && storeData.loginSlice && storeData.loginSlice.isLoggedIn === null) {
  //   setLogoutSuccess(true);
  //   setLogoutClick(false);
  // };

  // })
  let resolveloader = useSelector(state => (state.ResolveReducer?.loader !== null && state.ResolveReducer?.loader !== undefined) ? state.ResolveReducer.loader : false);
  let dashboardbody = {
    source: "traning_list",
    condition: {
      limit: 100,
      skip: 0,
    },
    sort: {
      field: "priority",
      type: "asc",
    },
    searchcondition: {
      status: 1,
      show_on_dashboard: 1,
    },
    project: {},
    token: "",
    email: "",
  };

  return (
    <div>
      {resolveloader && (
        <Box className='training_loaderTop' sx={{ width: "100%" }}>
          <app-progressbar></app-progressbar>
        </Box>
      )}
      {(store.getState().loginSlice.isLoggedIn ||
        (store.getState().loginSlice?.userInfo &&
          Object.keys(store.getState().loginSlice.userInfo).length > 0)) && pathname !== "/login" && pathname !== "/404" ? (
        <div className="backLink" onClick={() => navigate("/")}>
          <Tooltip title="Back to Home Page" followCursor>
            <HomeIcon />
          </Tooltip>
        </div>
      ) : null}

      <div className="admin_topbody">
        <Link to="/dashboard">
          <img
            src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/IWC_Logo.png"
            alt="logo"
            className="adminlogo"
          ></img>
        </Link>
        <div>
          <div className="admin_topRight">
            <div className="topuserblock">
              <div className="toggle_Container">
                <div className="toggle_button">
                  <button onClick={() => handleToggle("HeaderMenuToggle")}>
                    <MenuOpenSharpIcon />
                  </button>{" "}
                </div>
              </div>

              <div className="Dashbaord_TopPH">
                <a href={process.env.REACT_APP_IS_IWC_NET && process.env.REACT_APP_IS_IWC_NET === "true" ? "tel:212.671.2978" : "tel:801.888.8435"}>
                  <span>
                    <CallIcon />
                  </span>
                  {(process.env.REACT_APP_IS_IWC_NET && process.env.REACT_APP_IS_IWC_NET === "true") ? "212.671.2978" : "801.888.8435"}
                </a>
              </div>

              <div className="name_drop">
                <img className="mini_logo" src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/header_mini_logo.webp" />
                <PersonIcon className="PersonIcon" />{" "}
                <h1>
                  {" "}
                  {userInfo?.userInfo?.firstname && userInfo?.userInfo?.firstname!=="NA" && userInfo?.userInfo?.lastname && userInfo?.userInfo?.lastname!=="NA"
                    ? userInfo.userInfo.firstname +
                    " " +
                    userInfo.userInfo.lastname
                    : userInfo && userInfo.practiceContactData && userInfo.practiceContactData.facility_name?userInfo.practiceContactData.facility_name:""}
                </h1>
                <Button
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(evt) => handleClick(1, evt)}
                >
                  <KeyboardArrowDownIcon className="userarrowicon" />
                </Button>
              </div>
              {selectMenu === 1 && (
                <Menu
                  className="myAcnt_modal"
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClose}>
                    <Link to="/profile">My account</Link>
                  </MenuItem>
                  <MenuItem onClick={() => clickedLogout()}>Logout</MenuItem>
                </Menu>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={`admin_topNav ${isActive ? "navbar_off" : "navbar_on"}`}>





        <Button className={splitLocation[1] === "dashboard" ? "active" : ""}
          onClick={() =>
            resolvefunction(navigate, '/dashboard', [gettraingcatagorydata(dashboardbody)])
          }
        >
          Dashboard
          {/* <Link to="/dashboard"> Dashboard  </Link>{" "} */}
        </Button>

        {userInfo?.userInfo?.type !== "organiser" && userInfo?.userInfo?.type !== "user" ? (
          <Button className={splitLocation[1] === "usermanagement" ? "active" : ""}
            onClick={() =>
              resolvefunction(navigate, '/usermanagement', [getusersList(useManagementBody)])
            }
          >
            Users
            {/* <Link to="/usermanagement">Users </Link>{" "} */}
          </Button>)
          : ""}
<Button className={splitLocation[1] === "leads" ? "active" : ""}
            onClick={() =>
              resolvefunction(navigate, '/leads')
            }
          >
            Leads
            {/* <Link to="/usermanagement">Users </Link>{" "} */}
          </Button>


        {userInfo?.userInfo?.type !== "user" && userInfo?.userInfo?.type !== "practice" ? (<Button
          id="demo-positioned-button"
          aria-controls={open3 ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open3 ? 'true' : undefined}
          onClick={handleClick3}
          endIcon={<KeyboardArrowDownIcon />}
          className={splitLocation[1] === "training-category" || splitLocation[1] === "training-lesson" ? "active" : ""}
        >
          Training
        </Button>) : ""}
        {/* 
        {userInfo?.userInfo?.type !== "user" ? (<Button className={splitLocation[1] === "training-center" ? "active" : ""}><Link to="/training-center"> Training Center  </Link>{" "}</Button>) : ""} */}

        <Button
          className={splitLocation[1] === "training-center" ? "active" : ""}
          // onClick={() =>
          //   resolveToPage('/training-center', [gettessiondataforcenter])
          // }
          // onClick={() =>
          //   resolvefunction(navigate, "/training-center", [
          //     gettessiondataforcenter(),
          //   ])
          // }
          onClick={() =>
            resolvefunction(navigate, "/training-center/3")
          }
        >
          Training Center{" "}
        </Button>




        {userInfo?.userInfo?.type !== "user" && userInfo?.userInfo?.type !== "practice" ? (
          <Button className={splitLocation[1] === "avilibility-list" || splitLocation[1] === "booked-slot" || splitLocation[1] === "create-event" || splitLocation[1] === "edit-event" || splitLocation[1] === "available-slots" || splitLocation[1] === "miscellaneous" ? "active" : ""}
            onClick={() =>
              resolvefunction(navigate, '/avilibility-list', [fetchEventsData(eventListBody)])
            }
          >
            Calendar Availability
            {/* <Link to="/avilibility-list"> Calendar Availability  </Link>{" "} */}
          </Button>) : ""}



        <div>
          <Menu
            className="training_dropDwn"
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl3}
            open={open3}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {/* <MenuItem
              className={
                splitLocation[1] === "training-list"
                  ? "submenu_item active"
                  : "submenu_item"
              }
              onClick={handleClose3}
            >
              <Link to="/training-category/list">Training List</Link>
            </MenuItem>
            <MenuItem
              className={
                splitLocation[1] === "lesson-list"
                  ? "submenu_item active"
                  : "submenu_item"
              }
              onClick={handleClose3}
            >
              <Link to="/training-lesson/list">Lesson List</Link>
            </MenuItem> */}
            <MenuItem className={(splitLocation[1] === "training-list") ? "submenu_item active" : "submenu_item"}
              // onClick={handleClose3}
              onClick={() =>
                resolvefunction(navigate, '/training-category/list', [Traininglist()])
              }
            >
              {/* <Link to='/training-category/list' > Training List </Link> */}
              <span onClick={handleClose3}>Training List</span>
            </MenuItem>
            <MenuItem className={(splitLocation[1] === "lesson-list") ? "submenu_item active" : "submenu_item"}
              // onClick={handleClose3}
              onClick={() =>
                resolvefunction(navigate, '/training-lesson/list', [Traininglessonlist()])
              }
            >
              {/* <Link to='/training-lesson/list' >Lesson List</Link> */}
              <span onClick={handleClose3}>Lesson List</span>
            </MenuItem>

          </Menu>
        </div>
        {userInfo?.userInfo?.type == "admin" ? (
          <>
            <Button className={splitLocation[1] === "additional-training-category" || splitLocation[1] === "additional-training-lesson" ? "active" : ""}
              id="demo-positioned-button"
              aria-controls={open4 ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open4 ? 'true' : undefined}
              onClick={handleClick4}
              endIcon={<KeyboardArrowDownIcon />}>Manage Additional Videos {" "}
            </Button>

            <div>
              <Menu className='addVid_dropDwn'
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl4}
                open={open4}
                onClose={handleClose4}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem className={(splitLocation[1] === "additional-training-list") ? "submenu_item active" : "submenu_item"} onClick={() => { resolvefunction(navigate, '/additional-training-category/list', [AdditionalTrainingCatList()]) }}>

                  <span onClick={handleClose4}> Additional Training </span>
                </MenuItem>
                <MenuItem className={(splitLocation[1] === "additional-lesson-list") ? "submenu_item active" : "submenu_item"} onClick={() => { resolvefunction(navigate, '/additional-training-lesson/list', [AdditionalTrainingLessList()]) }}>
                  <span onClick={handleClose4}>Additional Lessons </span>
                </MenuItem>

              </Menu>
            </div>
          </>) : null}

        <Button
          className={splitLocation[1] === "additional-training-center" ? "active" : ""}
          // onClick={() =>
          //   resolveToPage('/training-center', [gettessiondataforcenter])
          // }
          onClick={() =>
            resolvefunction(navigate, "/additional-training-center", getdataforadditionalcenter()
            )
          }
        >
          Insdutry Expert Instructions{" "}
        </Button>



        <Button
          id="demo-Category-button"
          aria-controls={openCategory ? 'demo-Category-button' : undefined}
          aria-haspopup="true"
          aria-expanded={openCategory ? 'true' : undefined}
          onClick={openCategoryMenu}
          endIcon={<KeyboardArrowDownIcon />}
          className={splitLocation[1] === "category" || splitLocation[1] === "category-add" || categoryEditPath || splitLocation[1] === "cpticd" || splitLocation[1] === "cpticd-add" || cptCodesEditPath ? "active" : ""}
        >
          CPT/ICD Category
        </Button>

        <div>
          <Menu
            className="training_dropDwn"
            id="demo-Category-button"
            aria-labelledby="demo-Category-button"
            anchorEl={anchorCategory}
            open={openCategory}
            onClose={closeCategoryMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >

            <MenuItem className={(splitLocation[1] === "category" || splitLocation[1] === "category-add") ? "submenu_item active" : "submenu_item"}
              onClick={() => resolvefunction(navigate, "/category", CategoryListFetch())}
            >
              <span onClick={closeCategoryMenu}>Category</span>
            </MenuItem>
            <MenuItem className={(splitLocation[1] === "cpticd" || splitLocation[1] === "cpticd-add") ? "submenu_item active" : "submenu_item"}
              // onClick={handleClose3}
              onClick={() => resolvefunction(navigate, "/cpticd", CPTICDListFetch())}
            >
              <span onClick={closeCategoryMenu}>CPT/ICD</span>
            </MenuItem>

          </Menu>
        </div>

        {/* <Button
          className={splitLocation[1] === "category" ? "active" : ""}
          onClick={() => resolvefunction(navigate, "/category", CategoryListFetch())}
        >
          Category{" "}
        </Button>
        <Button
          className={splitLocation[1] === "cpticd" ? "active" : ""}
          onClick={() => resolvefunction(navigate, "/cpticd", CPTICDListFetch())}
        >
          CPTICD{" "}
        </Button> */}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snakbaropen}
        message={snackBarMsg}
        key="bottomcenter"
      />
    </div>
  );
}
