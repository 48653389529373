import { Grid, Paper, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "../../form/Form";
import { resetForm, setTempFormUpdateData } from "../../form/formReducer";
import store from "../../store";
// import { generatePassword } from "../../common/ReuseComponents/reqBody"

export const ResetPassword = () => {
    const _id1 = useSelector((state) => state.loginSlice.userInfo?._id);
    const [formSubmissionState, setFormSubmissionState] = useState(0);
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [queryParamId, setQueryParamId] = useState();  //*****FOR Seeting QueryP****//

    const queryParam = useLocation();
    const Navigate= useNavigate();
    const paramId = queryParam.search.slice(queryParam.search.indexOf('string=') + 7);
    
    // console.warn("<<<<<<<< param Id goes here >>>>> ",paramId)


    useEffect(() => {
        if (paramId) {
            setQueryParamId(paramId)
        }
    }, [paramId])

    // console.warn("<<<<<<<< param Id goes here >>>>> ",paramId)

    const passGenerateClicked = useSelector((state) =>
        state.formSlice.customButtonClicked?.passwordUpdateForm
            ? state.formSlice.customButtonClicked.passwordUpdateForm.password001
            : false
    );
    const copyPasswordClicked = useSelector((state) =>
        state.formSlice.customButtonClicked?.passwordUpdateForm
            ? state.formSlice.customButtonClicked.passwordUpdateForm.copypassword001
            : false
    );
    const dispatch = useDispatch();

    useEffect(() => {

        if (formSubmissionState === 2) {
            dispatch(resetForm({ formId: "passwordUpdateForm" }));
            setOpen(true);
            
            setTimeout(() => {
                Navigate('/');
            }, 2000);
        }
    }, [formSubmissionState]);

    store.subscribe(() => {
        const storeData = store.getState();
        //////////////////// Listener For form submission ///////////////////////
        setFormSubmissionState(
            storeData.formSlice.formSubmissionState.passwordUpdateForm
        );
    });



    const dataform = {
        id: "passwordUpdateForm",
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "api/reset-change-password",
        // endPointBodyWrapper: "data",
        submitBtnName: "Update",
        formButtonClass: "submitbuttonwrapper",

        formAdditionalSubmissionData: {
            _id: _id1 !== undefined ? _id1 : undefined,
            password_identifier: queryParamId ? queryParamId : undefined
        },

        fields: [


        ],
        customButtons: [
            {
                id: "cancel001",
                label: "Cancel",
                className: "default",
                type: "button",
            },
        ],
    };
    let arrayPassField = [
        {
            id: 14,
            // heading: "Password",
            label: "New Password",
            name: "new_password",
            className: "formGroup list_search_item_added countDiv__column--col4 ",
            type: "password",
            //   value: { password },
            // defaultValue: (editdataforform !== undefined && typeof editdataforform?.password !== undefined) ? password : undefined,
            rules: {
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
            },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid",
                pattern: "Your password must contain 6 characters with at least one lower case, upper case alphabets and special character",
            },
        },
        {
            id: 12,
            // heading: "Confirm Password",
            label: "Confirm Password",
            name: "confirm_new_password",
            className: "formGroup list_search_item_added countDiv__column--col4 ",
            type: "password",
            rules: {
                required: true,
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
            },
            errorMessage: {
                required: "This Field is Required",
                errorMessageConfirmPassword: "Password does not match",
                pattern: "Confirm password field value must be matched",
                custom: "Value is Invalid", ////////////////////// Include this for confirm password field //////////
            },
        },
    ];

    let arrayButtonField = [
        // {
        //     id: "password001",
        //     label: "Generate Password",
        //     className: "default",
        //     type: "button",
        // },
        // {
        //     id: "copypassword001",
        //     label: "Copy Password",
        //     className: "default",
        //     type: "button",
        // },
    ];

    arrayButtonField.forEach((curr) => dataform.customButtons.push(curr));

    arrayPassField.forEach((curr) => dataform.fields.push(curr));

    /****************************Generate Password************************ */


    useEffect(() => {
        // if (passGenerateClicked == true) generatePassword();
        if (passGenerateClicked == true) {
            // let pass = generatePassword(10);
            // console.log("Password***************************", pass);
            // setPassword(pass);
        }
        console.log("password123==========>", password);
    }, [passGenerateClicked]);

    const copyPassword = () => {
        navigator.clipboard.writeText(password);
    };

    useEffect(() => {
        if (copyPasswordClicked == true) copyPassword();
    }, [copyPasswordClicked]);

    useEffect(() => {
        dispatch(setTempFormUpdateData({ formId: 'passwordUpdateForm', fieldName: "password", value: password }))
    })

    return (
        <div className="loginMain_wrpr Reset_pass  forgetPass">

            <div className="login_bodyBG_block1 trbu"></div>

            <div className="login_bodyBG_block2 trbu"></div>

            <div className="login_bodyBG_block3 trbu"></div>

            <Grid container className="login_body">


                <Grid item className="loginLogoSection" md={6} sm={12}>
                    <div className="logo-Wrpr">
                        <img
                            src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iWC_LoginLogo.png"
                            alt=""
                        />
                    </div>
                </Grid>

                <Grid
                    item className="login_Section" md={6} sm={12}
                >
                    <Grid className="login_main_wrpper">

                    <div className="logo-Wrpr">
                            <img
                                src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iWC_LoginLogo.png"
                                alt=""
                                />
                             </div>

                        <Typography component="h1" variant="h5">
                            Reset Password
                        </Typography>
                        <Box elevation={7} className="list_search team_management_list_search">
                            <Form formData={dataform} />
                        </Box>

                        <Snackbar
                            open={open}
                            onClose={() => setOpen(false)}
                            autoHideDuration={130000}
                            message="Password Changed Successfully"
                        />
                    </Grid>

                </Grid>

            </Grid>

            {/* </div> */}
        </div>
    );
};
