import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// API call for users-list
export const getusersList = createAsyncThunk("users/list", async (reqbody) => {

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body,)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/listingusers", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const getLeadList = createAsyncThunk("users/leadlist", async (reqbody) => {

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body,)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "calendarapi/google-events-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const fetchSingleDataForLead = createAsyncThunk("users/singleDataForLead", async (reqbody) => {

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body,)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "calendarapi/google-events-list-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});




///////////////////////////////// Status Update //////////////////////////////////
export const usermanagestatusUpdate = createAsyncThunk("statusUpdateUserManagement", async (reqbody) => {

    console.warn("<<<<< usermanagestatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": [reqbody._id], "status": reqbody.status == 1 ? 0 : 1 })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/userstatuschange", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});


///////////////////////////////// Delete User //////////////////////////////////
export const userManagementDelete = createAsyncThunk("usermanagementDelete", async (reqbody) => {

    // console.warn("<<<<< userManagementDelete >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": [reqbody._id] })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/deleteuserdata", requestOptions);
    const respdataforUserDelete = await response.json();

    return respdataforUserDelete;
});


///////////////////////////////// Multiple Delete User //////////////////////////////////
export const userManagementMultiDelete = createAsyncThunk("userManagementMulti/Delete", async (reqbody) => {

    // console.warn("<<<<< userManagementDelete >>>>>>", reqbody)

    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqbody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/deleteuserdata", requestOptions);
    const respdataforUserMultiDelete = await response.json();

    return respdataforUserMultiDelete;
});


///////////////////////////////// Multiple usermanageMultiStatusUpdate User //////////////////////////////////
export const usermanageMultiStatusUpdate = createAsyncThunk("userManagementMulti/Delete", async (reqbody) => {


    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqbody.copyArr, status: reqbody.statusAction })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/userstatuschange", requestOptions);
    const usermanageMultiStatusUpdate = await response.json();

    return usermanageMultiStatusUpdate;
});


/////////////////////////////////  ResendWelcomeEmail //////////////////////////////////
export const userManagementResendWelcomeEmail = createAsyncThunk("usermanagement/resendwelcomeemail", async (reqbody) => {

    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": reqbody._id })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api/resend-welcomemail", requestOptions);
    const managementResendWelcomeEmail = await response.json();

    return managementResendWelcomeEmail;
});


/////////////////////////////////  sendResetPassword //////////////////////////////////
export const sendResetPassword = createAsyncThunk("usermanagement/resetpassword", async (reqbody) => {

    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": reqbody.email })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api/reset-password", requestOptions);
    const sendResetPassword = await response.json();

    return sendResetPassword;
});


/////////////////////////////////  logMeIn //////////////////////////////////
export const logMeIn = createAsyncThunk("usermanagement/logmein", async (reqbody) => {
    console.warn("<<<<<<<< payload from logMeIn thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "admin_id": reqbody.admin_id, "log_me_id": reqbody.log_me_id })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api/log-in-as-user", requestOptions);
    const logMeIn = await response.json();

    return logMeIn;
});


/////////////////////////////////  logInInfo //////////////////////////////////
export const logInInfo = createAsyncThunk("usermanagement/logininfo", async (reqbody) => {
    console.warn("<<<<<<<< payload from logInInfo thunk >>>>", reqbody)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "source": "users",
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
                "_id": reqbody._id
            }
        })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/user-login-details-list", requestOptions);
    const logMeIn = await response.json();

    return logMeIn;
});



const userManagmentList = createSlice({
    name: 'userManagment',
    initialState: {
        submitting: false,
        users: [],
        loading: false,
        submitted: false,
        resMessage: null,
        usersList: null,
        successmsg: null,
        success: false,
        failed: false,
        currentEditUserData: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        successAction: false,
        loadinglist: false,
        logininfo: null,
        loginResMessage: null,
        userNotLoginYet: false,
        leadList: [],
        singleLead: null
    },

    reducers: {

        setSuccessAfterSubmit(state, action) {
            state.success = false;
            state.failed = false;
        },
        currentEditUserData(state, action) {
            state.currentEditUserData = action.payload
        },
        setMessagefterSubmit(state, action) {
            state.messageflag = false;
            state.message = null;
        },
        setTokenRequiredStatus(state, action) {
            state.tokenRequired = false
        },
        clearUsersData(state, action) {
            state.users = [];
        },
        clearLeadData(state, action) {
            state.leadList = [];
        },
        resetAllStates(state, action) {
            // state.loading= false;
            // state.submitted= false;
            // state.resMessage= null;
            // state.usersList= null;
            // state.successmsg= null;
            // state.success= false;
            // state.failed= false;
            // state.currentEditUserData= null;
            // state.tokenRequired= false;
            // state.tokenRequiredMsg= '';
            // state.successAction= false;
            // state.loadinglist= false;
            state.logininfo = null;
            state.loginResMessage = null;
            state.userNotLoginYet = false;
        },

    },
    extraReducers: {

        /// #################### USER LISting ###############################
        [getusersList.rejected]: (state, action) => {
            state.loadinglist = false;
            state.successAction = false;
        },
        [getusersList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loadinglist = true;
            state.successAction = false;

        },
        [getusersList.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.usersList = action.payload.results.res;
                state.message = action.payload.status;
                state.loadinglist = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            if (action.payload.status === 'error') {
                if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    state.tokenRequired = true;
                    state.tokenRequiredMsg = action.payload.results;
                }
            }
        },
        [getLeadList.rejected]: (state, action) => {
            state.loadinglist = false;
            state.successAction = false;
        },
        [getLeadList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loadinglist = true;
            state.successAction = false;

        },
        [getLeadList.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.leadList = action.payload.results.res;
                state.message = action.payload.status;
                state.loadinglist = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            if (action.payload.status === 'error') {
                if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    state.tokenRequired = true;
                    state.tokenRequiredMsg = action.payload.results;
                }
            }
        },
[fetchSingleDataForLead.rejected]: (state, action) => {
            state.loadinglist = false;
            state.successAction = false;
        },
        [fetchSingleDataForLead.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loadinglist = true;
            state.successAction = false;

        },
        [fetchSingleDataForLead.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.singleLead = action.payload.results.res;
                state.message = action.payload.status;
                state.loadinglist = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            if (action.payload.status === 'error') {
                if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    state.tokenRequired = true;
                    state.tokenRequiredMsg = action.payload.results;
                }
            }
        },




        /////////////////////////// status update ///////////////////////////////
        [usermanagestatusUpdate.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.failed = false;
            state.successAction = false;


        },
        [usermanagestatusUpdate.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Updated Successfully'
                // state.success = true;
                state.successAction = true;
                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [usermanagestatusUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.failed = true;
            state.resMessage = 'Something went wrong';
        },



        /////////////////////////// Delete User ///////////////////////////////
        [userManagementDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [userManagementDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [userManagementDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },




        /////////////////////////// Multiple Delete User ///////////////////////////////
        [userManagementMultiDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [userManagementMultiDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [userManagementMultiDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },


        /////////////////////////// Multi Status Update User ///////////////////////////////
        [usermanageMultiStatusUpdate.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [usermanageMultiStatusUpdate.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Status Update Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [usermanageMultiStatusUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        /////////////////////////// Resend Welcome Email /////////////////////////
        [userManagementResendWelcomeEmail.pending]: (state, action) => {
            state.loading = true;
            state.resMessage = null
            state.success = false;

        },
        [userManagementResendWelcomeEmail.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Mail Sent Successfully'
                state.success = true;
            }
        },
        [userManagementResendWelcomeEmail.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },


        /////////////////////////// sendResetPassword ///////////////////////////
        [sendResetPassword.pending]: (state, action) => {
            state.loading = true;
            state.success = false;

        },
        [sendResetPassword.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Reset Password Link Sent Successfully'
                state.success = true;
            }
        },
        [sendResetPassword.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        /////////////////////////// logMeIn ///////////////////////////
        [logMeIn.pending]: (state, action) => {
            state.loading = true;
        },
        [logMeIn.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Successfully Login'
                state.success = true;
            }
        },
        [logMeIn.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        /////////////////////////// logininfo ///////////////////////////
        [logInInfo.pending]: (state, action) => {
            state.loading = true;
            state.loginResMessage = null;
            state.resMessage = null;
            state.logininfo = null;
            state.userNotLoginYet = false;
        },
        [logInInfo.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.loginResMessage = null;
                state.logininfo = action.payload?.results?.res ? action.payload.results.res : null;

                // console.warn("<<<<<< login info details >>>>>", state.logininfo)
            } else {
                state.userNotLoginYet = true;
                state.logininfo = null;

            }
        },
        [logInInfo.rejected]: (state, action) => {
            state.loading = false;
            state.logininfo = null;
            state.resMessage = 'Something went wrong';

        },



    }
})

export default userManagmentList.reducer;
export const { setSuccessAfterSubmit, setTokenRequiredStatus, clearUsersData, clearLeadData, resetAllStates } = userManagmentList.actions;