import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// import { resetForm } from "../form/formReducer";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import store from "../../store";
import { Controller, useForm } from "react-hook-form";
import { clearMessageIsLoggedIn, forgotPassword } from "../Login/loginReducer";
import { AccountCircle } from "@mui/icons-material";
// import { forgotPassword } from "./AccountReducer";

const ForgotPassword = () => {
  const [loading, setloading] = useState(false);
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [opensnack, setOpensnack] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessages, setErrorMessages] = useState({
    required: "This Field is Required",
    validate: "Please Enter Valid Email",
    pattern: "Invalid Email Address",
  });
  const dispatch = useDispatch();
  /////////////////////////////// Hook Form /////////////////////////////////////////////
  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();
  //////////////////////////////// Form Submission //////////////////////////////////////
  const onSubmit = async (data) => {
    // data.link = `${process.env.REACT_APP_API_EXTERNAL_URL}forgot-password/`;
    console.log("first------>datafp", data);
    dispatch(forgotPassword(data));
  };

  store.subscribe(() => {
    const storeData = store.getState();
    setFormSubmissionState(
      storeData.formSlice.formSubmissionState.createEventForm
    );
    // console.log("storeData in pagwwwwww", storeData.formSlice);
    if (storeData.formSlice.formSubmissionState.createEventForm === 2) {
      setOpensnack(true);
    }
    if (storeData.formSlice.message?.createEventForm) {
      setMessage(storeData.formSlice.message.createEventForm);
    }
  });

  const resMessage = useSelector((state) =>
    state.loginSlice && state.loginSlice?.resetPassMSg
      ? state.loginSlice?.resetPassMSg
      : null
  );

  useEffect(() => {
    if (resMessage !== null) {
      setOpensnack(true);
      setMessage(resMessage);
    } else {
      setOpensnack(false);
    }
  }, [resMessage]);

  useEffect(() => {
    if (formSubmissionState == 1) {
      setloading(true);
    }

    if (formSubmissionState == 2) {
      setloading(false);
      // dispatch(resetForm({ formId: "createEventForm" }));
    }
  }, [formSubmissionState]);

  useEffect(() => {
    console.log("opensnack", opensnack);
    if (opensnack) {
      setTimeout(() => {
        setOpensnack(false);
      }, 3000);
    }
  }, [opensnack]);

  return (
    <div className="forgetPass loginMain_wrpr">
      <div className="login_bodyBG_block1 trbu"></div>

      <div className="login_bodyBG_block2 trbu"></div>

      <div className="login_bodyBG_block3 trbu"></div>

      <Grid container className="login_body">
        <Grid item className="loginLogoSection" md={6} sm={12}>
          <div className="logo-Wrpr">
            <img
              src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iWC_LoginLogo.png"
              alt=""
            />
          </div>
        </Grid>
        {loading && <app-progressbar />}
        {/* <Button className="GoToHome GoToHomelogin">
                  <Link to="/">
                    <Tooltip title="Go To Home">
                      <OtherHousesIcon />
                    </Tooltip>
                  </Link>
                </Button> */}

        <Grid
          item className="login_Section" md={6} sm={12}
        >
          {loading && <app-progressbar />}

          <Grid className="login_main_wrpper" >

            <div className="logo-Wrpr">
              <img
                src="https://all-frontend-assets.s3.amazonaws.com/wound-care-for-home_Assets/iWC_LoginLogo.png"
                alt=""
              />
            </div>

            <Typography component="h1" variant="h5">
              Forget Password
            </Typography>

            <Box className="emailBox" sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  }}
                  render={() => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      type="text"
                      autoFocus
                      onChange={(e) => {
                        setValue(
                          "email",
                          e.target.value?.toLowerCase().trim()
                        );
                        clearErrors("email");
                        dispatch(clearMessageIsLoggedIn());
                      }}
                      error={
                        Object.keys(errors).length > 0 && errors.email != null
                      }
                      helperText={
                        errors.email && errorMessages[errors.email.type]
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" edge="end">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />



                <Button
                  className="passBttn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  disabled={loading}
                >
                  <span> SUBMIT </span>
                </Button>
              </form>
              {loading && <app-progressbar />}
              <Grid container>
                {/* <Grid item xs /> */}
                <Grid item display="flex" alignItems="center">
                  <Link className="forgot_password" to="/login">
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </Grid>

        {/* SnackBar Goes Here */}

        {/* {opensnack} */}

        <Snackbar
          open={opensnack}
          autoHideDuration={130000}
          onClose={() => {
            setOpensnack(false);
            setMessage(null);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              setOpensnack(false);
              setMessage(null);
            }}
            // severity={"success"}
            sx={{ width: "100%" }}
          >
            {message && message}
          </Alert>
        </Snackbar>




      </Grid>
    </div>
  );
};

export default ForgotPassword;
