import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StateArray from '../../assets/json/state';
import { Form } from '../../form/Form';
import { resolvefunction } from "../../helper/helperFunctions";
import store from "../../store";
import { fetchCalenderEvents } from "../Calendar/calenderReducer";
import { setBackendUserInfo, setFrontendUserInfo } from "../Login/loginReducer";

const UserSignUpForm = (props) => {

    const cookies = new Cookies();
    const getcookies = cookies.getAll();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [frontendUserInfo, setFrontendUserCookie] = useCookies(["frontendUserInfo"]);
    const [backendUserInfo, setBackendUserInfoCookie] = useCookies(["backendUserInfo"]);
    const [locationArry, setLocationArry] = useState([]);
    const [submissionState, setSubmissionState] = useState(0);
    const [loading, setloading] = useState(false);

    const formInputData = useSelector((state) =>
        state.formSlice.formData.leadform ? state.formSlice.formData.leadform : null
    );
    let currTime = new Date().getTime();
    let dayAfterTomorrow = (currTime + (48 * 3600 * 1000));
    const [slotListBody, setSlotListBody] = useState({
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "field": "start_datetime_unix",
            "type": "desc"
        },
        "searchcondition": {
            // userid: getcookies.userInfo?._id,
            "start_datetime_unix": {
                "$gte": dayAfterTomorrow
            }
        },
        "slot_creation_type": props.type === 'np' ? 2 : 1
    })



    useEffect(() => {
        let Newarr = [];
        if (StateArray && StateArray.length > 0) {
            for (let i in StateArray) {
                Newarr.push({ val: StateArray[i].name, name: StateArray[i].name });
            }
            setLocationArry(Newarr);
        }
    }, []);
    useEffect(() => {
        if (submissionState && submissionState === 4) {
            addapicall(formInputData);
        }
    }, [submissionState]);

    const addapicall = async (dataccc) => {
        let data = dataccc;
        console.log("websiteInformation data", data);
        setloading(true);
        props.formSubmission(true)

        if (data != null) {
            // data.website_information']=websiteInformation
            const requestOptions = {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(data),
            };
            const response = await fetch(
                process.env.REACT_APP_API_URL + "api/submit-intake-form",
                requestOptions
            );
            // console.log("++++response+++++++",response);

            const respdata = await response.json();
            console.log("++++cv+++++++", respdata);

            if (respdata && respdata.status === "success") {
                if (props.type === "frontend") {
                    if (getcookies.backendUserInfo) {
                        cookies.remove("backendUserInfo", { path: "/" });
                    }
                    setFrontendUserCookie("frontendUserInfo", JSON.stringify(respdata.results), { path: "/" });
                    dispatch(setFrontendUserInfo(respdata.results));
                }
                if (props.type === "np") {
                    if (getcookies.frontendUserInfo) {
                        cookies.remove("frontendUserInfo", { path: "/" });
                    }
                    setBackendUserInfoCookie("backendUserInfo", JSON.stringify(respdata.results), { path: "/" });
                    dispatch(setBackendUserInfo(respdata.results));
                }

                resolvefunction(navigate, `/calendar${props.type === 'np' ? '-backend' : ""}/${respdata.results._id}`, [
                    fetchCalenderEvents(slotListBody),
                ]);
                props.formSubmission(false)
                // navigate(`/calendar${props.type === 'np' ? '-backend' : ""}/${respdata.results._id}`);
                setloading(false);
            } else {
                props.formSubmission(false);
                setloading(false);
            }
        }
    };

    let homeForm = [
        //////////////////////////////////// Text Input Event Title ///////////////////////////////
        {
            id: 0,
            // heading: "First Name",
            label: "First Name",
            name: "firstname",
            className: "inputBlock ",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: "",
        },
        {
            id: 1,
            label: "Last Name",
            name: "lastname",
            className: "inputBlock",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: "",
        },
        {
            id: 2,
            label: "Practice Name",
            name: "practicename",
            className: "inputBlock",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: "",
        },

        {
            id: 3,
            label: "Email",
            name: "email",
            className: "inputBlock",
            type: "text",
            rules: {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
            errorMessage: {
                required: "This Field is Required",
                pattern: "Enter a valid email",
            },
        },
        {
            id: 4,
            label: "Phone No",
            name: "phone",
            className: "inputBlock",
            type: "text",
            inputType: "phone",
            required: "This Field is Required",
            rules: { required: true, minLength: 10 },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "minLength is 10 allowed",
            },
        },
        {
            id: 5,
            label: "State",
            name: "state",
            className: "inputBlock state inputBlock4line",
            type: "select",
            // checkbox: true,
            other: true,
            values: locationArry,
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "Value is Invalid",
            },
            defaultValue: "",
        },
        {
            id: 6,
            label: "City",
            name: "city",
            className: "inputBlock",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: "",
        },

        {
            id: 7,
            label: "Website (optional)",
            name: "website",
            className: "inputBlock",
            type: "text",
            // rules: { required: true },
            // errorMessage: {
            //     required: "This Field is Required",
            //     maxLength: "MaxLength length 20 allowed",
            // },
            defaultValue: "",
        },

        {
            id: 8,
            heading: "SELECT YOUR SPECIALITY",
            name: "speciality",
            className: "multipleCheckbox",
            hint: "In months",
            type: "multipleCheckbox",
            values: [
                { key: "Nurse Practitioner", val: "Nurse Practitioner" },
                { key: "Family Practice", val: "Family Practice" },
                { key: "General Practice", val: "General Practice" },
                { key: "Internal Medicine", val: "Internal Medicine" },
                { key: "Integrated Practice", val: "Integrated Practice" },
                { key: "Chiropractice with NP or Doctor", val: "Chiropractice with NP or Doctor" },
            ],
            // rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid",
            },
        },
        {
            id: 9,
            heading: "Privatly owned",
            name: "privatelyowned",
            className: 'radio',
            values: [{ key: 0, val: 'Yes' }, { key: 1, val: 'No' }],
            type: 'radio',
            hint: 'Yes/No',
            // rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                custom: "Value is Invalid"
            },
            // defaultValue: "No"
        },
    ];
    let dashboardForm = [
        //////////////////////////////////// Text Input Event Title ///////////////////////////////
        {
            id: 0,
            label: "First Name",
            name: "firstname",
            className: "inputBlock training_form3line",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.firstname ? props.dashboardFormData.firstname : '',
        },
        {
            id: 1,
            label: "Last Name",
            name: "lastname",
            className: "inputBlock training_form3line",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.lastname ? props.dashboardFormData.lastname : '',
        },
        {
            id: 2,
            label: "Practice Name",
            name: "practicename",
            className: "inputBlock training_form3line",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.practice_name ? props.dashboardFormData.practice_name : '',
        },
        {
            id: 3,
            label: "Phone No",
            name: "phone",
            className: "inputBlock training_form4line",
            type: "text",
            inputType: "phone",

            rules: { required: true, minLength: 10 },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "minLength is 10 allowed",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.phone ? props.dashboardFormData.phone : ''
        },
        {
            id: 4,
            label: "Email",
            name: "email",
            className: "inputBlock training_form4line",
            type: "text",
            rules: {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
            errorMessage: {
                required: "This Field is Required",
                pattern: "Enter a valid email",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.email ? props.dashboardFormData.email : ''
        },

        {
            id: 5,
            label: "State",
            name: "state",
            className: "inputBlock usertypebox training_form4line",
            type: "select",
            // checkbox: true,
            other: true,
            values: locationArry,
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.state ? props.dashboardFormData.state : '',
        },
        {
            id: 6,
            label: "City",
            name: "city",
            className: "inputBlock training_form4line",
            type: "text",
            rules: { required: true },
            errorMessage: {
                required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.city ? props.dashboardFormData.city : '',
        },
        {
            id: 7,
            name: "meeting_explaination",
            className: 'description',
            type: 'textarea',
            placeholder: 'Please explain your request for this meeting',
            rows: 6,
            // rules: { required: true },
            errorMessage: {
                // required: "This Field is Required",
                maxLength: "MaxLength length 20 allowed",
            },
            defaultValue: props.dashboardFormData && props.dashboardFormData.meeting_explaination ? props.dashboardFormData.meeting_explaination : ''
        },
    ];

    const formData = {
        id: "leadform",
        api_url: process.env.REACT_APP_API_URL,
        // endPoint: "api/submit-intake-form",
        // urlPathOnSuccessfulFormSubmission: "/Calender",
        formButtonClass: "submitbtnsection",
        fields: props.type === "frontend" ? homeForm : dashboardForm,
        formAdditionalSubmissionData: {
            type: props.type
        },
    };

    store.subscribe(() => {
        const storeData = store.getState();
        if (storeData && storeData.formSlice && storeData.formSlice.formSubmissionState && storeData.formSlice.formSubmissionState.leadform) {
            setSubmissionState(storeData.formSlice.formSubmissionState.leadform);
        }
        // console.log("landing store", storeData)
    })

    return (
        <>
            <div className="backend_form_headwrapp"><h2>{props.type === "frontend" ? 'Set a Zoom meeting with a Wound Care expert from our team right now!' : 'You are welcome to schedule a 15 minute zoom consultation with our on staff NP if you have questions or need assistance. Please fill out the following and then choose a time to speak with her!'}</h2></div>
            <Form formData={formData} />



            {loading ? <app-progressbar /> : null}

        </>
    )
}

export default UserSignUpForm;