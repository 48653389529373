import { Outlet, Link, useMatch } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import BackendHeader from "./Pages/Layout/BackendHeader";
import BackendFooter from "./Pages/Layout/BackendFooter";
import store from "./store.js";
import { Box, IconButton, LinearProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Header from "./Pages/Layout/Header";
import { setSnackbar } from "./Pages/Layout/layoutReducer";
import { Close } from "@mui/icons-material";

let storedata = null;

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const calendarInnerPageMatch = useMatch("/calendar/:lead_id");
  const npCalendarInnerPageMatch = useMatch("/calendar-backend/:lead_id");
  const thankyouPageMatch = useMatch("/thankyou/:page/:lead_id");
  const cancelSlotPageMatch = useMatch("/cancel-slot/:identifier");
  const traingonlyidMatch = useMatch("/training-center/:cat_id");
  const additonalcenterwithid = useMatch("/additional-training-center/:cat_id/:lesson_id");
  const additonalcenterwithcatid = useMatch("/additional-training-center/:cat_id");
  const cptCodesIDPath = useMatch("/cpt-codes/:_id");
  const contractSignup1Match = useMatch("/contract-signup1/:_id");

  const open = useSelector((state) =>
    state.layoutSlice.open ? state.layoutSlice.open : false
  );
  const message = useSelector((state) =>
    state.layoutSlice.message ? state.layoutSlice.message : ""
  );

  useEffect(() => {
    setTimeout(() => {
      if (open === true) dispatch(setSnackbar({ open: false, message: "" }));
    }, 7000);
  }, [open]);
  // console.log("pathname==>", pathname);

  // const store.getState();

  useEffect(() => {
    store.subscribe(() => {
      storedata = store.getState();
      // console.log(
      //   "storeData layout",
      //   storedata.loginSlice.loginRouteClicked,
      //   "islogg",
      //   storedata.loginSlice.isLoggedIn
      // );
      if (storedata.loginSlice && storedata.loginSlice.isLoggedIn == null) {
        if (!storedata.loginSlice.loginRouteClicked) {
          // navigate("/");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (
      !traingonlyidMatch &&
      pathname !== "/training-center" &&
      !additonalcenterwithid &&
      !additonalcenterwithcatid
    ) {
      console.log("scroll hit");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pathname]);

  return (
    <>
      {/* {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )} */}

      {pathname !== "/login" &&
        (store.getState().loginSlice.isLoggedIn ||
          (store.getState().loginSlice?.userInfo &&
            Object.keys(store.getState().loginSlice.userInfo).length > 0)) &&
        pathname !== "/" &&
        pathname !== "/programs" &&
        pathname !== "/trainings" &&
        pathname !== "/client-acquisition" &&
        pathname !== "/contact-us" &&
        pathname !== "/dermacyte" &&
        pathname !== "/xwrap" &&
        pathname !== "/404" &&
        pathname !== "/reset-password" &&
        pathname !== "/forget-password" &&
        pathname !== "/practice-signup" &&
        !contractSignup1Match &&
        !thankyouPageMatch &&
        !calendarInnerPageMatch &&
        !npCalendarInnerPageMatch &&
        !cancelSlotPageMatch && (
          <div>
            <BackendHeader />
          </div>
        )}

      {(pathname == "/" ||
        pathname === "/programs" ||
        pathname === "/trainings" ||
        pathname === "/client-acquisition" ||
        pathname === "/contact-us" ||
        pathname === "/dermacyte" ||
        pathname === "/xwrap" ||
        pathname === "/practice-signup" ||
        contractSignup1Match)
        &&
        !cptCodesIDPath &&
        !thankyouPageMatch &&
        !cancelSlotPageMatch &&
        !npCalendarInnerPageMatch &&
        !calendarInnerPageMatch && (
          <div>
            <Header />
          </div>
        )}

      <div className="min_height_wrp">
        <Outlet />
      </div>
      {/* {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )} */}

      {pathname !== "/login" && pathname !== "/404" && (
        <div>
          <BackendFooter />
        </div>
      )}

      <Snackbar
        className="form_error_snackbar"
        open={open}
        autoHideDuration={6000}
        onClose={() => dispatch(setSnackbar({ open: false, message: "" }))}
        message={message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() =>
              dispatch(setSnackbar({ open: false, message: "" }))
            }
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default withCookies(Layout);
