import { configureStore } from "@reduxjs/toolkit";
import tableSlice from "./listing/listReducer"

import formSlice from './form/formReducer'
import loginSlice from './Pages/Login/loginReducer';
import fileUploaderSlice from './form/fileUploaderReducer'
import TrainingReducer from "./Pages/Backend/Training/TrainingReducer";
import userManagmentList from "./Pages/Backend/userManagement/userListing/userListingReducer";
import fetchUserDataReducer from "./Pages/Backend/userManagement/fetchUserDataReducer"
import calenderEvents from './Pages/Calendar/calenderReducer'
import dashboardReducer from "./Pages/Backend/dashboard/dashboardReducer";
import layoutSlice from "./Pages/Layout/layoutReducer";
import ResolveReducer from "./helper/ResolveReducer";
import categoryRightBlockSLice from "./Pages/Backend/Training/TrainingCenter/TrainingRightBlock/CategoryBlock/categoryBlockReducer";
import AdditionalTrainingReducer from "./Pages/Backend/AdditionalTraining/AdditionalTrainingReducer";
import searchSlice from './search/searchReducer'
import CategoryReducer from "./Pages/Backend/CategoryManagement/CategoryReducer";
import CPTICDReducer from "./Pages/Backend/CPTICDManagement/CPTICDReducer";


export default configureStore({
    reducer: {
        loginSlice,
        layoutSlice,
        formSlice: formSlice,
        tableSlice: tableSlice,
        fileUploaderSlice,
        trainingSlice: TrainingReducer,
        userManagmentList: userManagmentList,
        fetchUserDataReducer: fetchUserDataReducer,
        calenderEventsStore: calenderEvents,
        dashboardReducer: dashboardReducer,
        categoryRightBlockSLice: categoryRightBlockSLice,
        ResolveReducer: ResolveReducer,
        additionalTrainingSlice: AdditionalTrainingReducer,
        searchSlice: searchSlice,
        categoryReducer: CategoryReducer,
        cpticdreducer: CPTICDReducer
    },
});