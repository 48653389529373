import { Button } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom"
import { timeConverter } from "../../helper/helperFunctions";
import { setLogedinUserInfo } from "../Login/loginReducer";
// import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';



export const CalendarAllTabs = (props) => {
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    const titleBarLocation = useLocation();
    const stroreDispatch = useDispatch();

  const [isActive, setActive] = useState("false");


  const handleToggle = (temp) => {
    if (temp == "HeaderMenuToggle") setActive(!isActive);
  };


    const [connectgmailaccount, setconnectgmailaccount] = useState(getcookies.userInfo?.connected_gmail);
    const [googlecaledersynctime, setgooglecaledersynctime] = useState(getcookies.userInfo?.google_calendar_connect_time);
   

    const GOOGLE_AUTHENTICATION_URL = process.env.REACT_APP_API_ENV === "isDev" ? process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL : process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL;

 useEffect(() => {
        if (titleBarLocation && titleBarLocation.search) {
            let locationSplit = titleBarLocation.search.split('&');
            let connectionTime = locationSplit[0].split('=');
            let connectionGmail = locationSplit[1].split('=');
            let currentUserInfo = cookies.get('userInfo') ? cookies.get('userInfo') : {};
            console.log("first console", connectionGmail, "vd", connectionTime);
            setconnectgmailaccount(connectionGmail[1]);
            setgooglecaledersynctime(parseInt(connectionTime[1]));
            currentUserInfo.google_calendar_connect_time = parseInt(connectionTime[1]);
            currentUserInfo.connected_gmail = connectionGmail[1];
            cookies.set('userInfo', currentUserInfo, { path: '/' })
            stroreDispatch(setLogedinUserInfo(currentUserInfo));
        };
    }, [])
    return (
        <>
            {/* {getcookies.userInfo?.lead_organizer ?"" :
<> */}
            <div className="connectedemail">
                <h2>{
                    props.from === 'createEvent' ? "Create Event" :
                        props.from === 'misc' ? "Miscellaneous" :
                            props.from === 'eventList' ? "Availability List" :
                                props.from === 'eventSlot' ? "Availability Slot" :
                                    "Booked Slot"}</h2>
                <div className="calenderinfo">
                    <label>Connected With Google Calendar </label>
                    <p>
                        <strong>Last Updated On:</strong><span>{timeConverter({ time: new Date(googlecaledersynctime), format: "L" })} </span><br />
                        <strong>  Email Connected:</strong> <span>{connectgmailaccount}</span>
                    </p>
                </div>
            </div>


            <div className="toggle_Icon">
                <div className="toggle_Iconwrp">
                  <button onClick={() => handleToggle("HeaderMenuToggle")}>
                    <MenuOpenTwoToneIcon />
                  </button>{" "}
                </div>
            </div>

            <div className={`buttonsetToolbar ${isActive ? "menu_off" : "menu_on"}`}>

                {props.from !== 'createEvent' ?
                    <Button variant="contained">
                        <Link to="/create-event">
                            Create New Availability
                        </Link>
                    </Button>
                    : ''}
                {props.from !== 'eventSlot' ?
                    <Button variant="contained">
                        <Link to="/available-slots">
                            Availability Slot
                        </Link>
                    </Button>
                    : ""}
                {props.from !== 'eventList' ?
                    <Button variant="contained">
                        <Link to="/avilibility-list">
                            Availability List
                        </Link>
                    </Button>
                    : ''}

                {/* </>} */}

                <Button href={`${GOOGLE_AUTHENTICATION_URL}?id=${getcookies.userInfo?._id}`} variant="contained">
                    Update Google Account
                </Button>
                {/* {getcookies.userInfo?.lead_organizer ?"" : */}
                {props.from !== 'bookedSlot' ?
                    <Button variant="contained">
                        <Link to="/booked-slot">
                            Booked Slot
                        </Link>
                    </Button>
                    : ""}
                {/* } */}
                {props.from !== 'misc' && getcookies.userInfo?.type !== "organiser" && getcookies?.userInfo?.type !== "user" ?
                    <Button variant="contained">
                        <Link to="/miscellaneous">
                            Miscellaneous
                        </Link>
                    </Button>
                    : ""}
            </div>
        </>
    )
}