import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import store from "../../store";
import { clearMessageIsLoggedIn, login, setIpinfo } from "./loginReducer";
import {
  gettraingcatagorydata,
  resetendpointSuccess,
} from "../Backend/dashboard/dashboardReducer";
import { setLoader, setResloved } from "../Layout/layoutReducer";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loginExpired, setLoginExpired] = useState(false);
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [token, setUserTokenCookie] = useCookies(["token"]);
  const [isdisabled, setIsdisabled] = useCookies(false);
  const [lastLoginTime, setLastLoginTimeCookie] = useCookies(["lastLoginTime"]);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);

  const resolved = useSelector((state) =>
    state.dashboardReducer.endpointSuccess &&
      state.dashboardReducer.gettraingcatagorydataSuccess
      ? state.dashboardReducer.gettraingcatagorydataSuccess
      : false
  );

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();
  const [errorMessages, setErrorMessages] = useState({
    required: "This Field is Required",
    validate: "Please Enter Valid Email",
    pattern: "Invalid Email Address",
  });

  let ipdetails = useSelector((state) =>
    state.loginSlice.ipInfo ? state.loginSlice.ipInfo : null
  );
  // const loading = useSelector((state) =>
  //   state.loginSlice.loading ? state.loginSlice.loading : false
  // );
  // const message = useSelector((state) =>
  //   state.loginSlice.message ? state.loginSlice.message : null
  // );
  const isLoggedIn = useSelector((state) => {
    if (state.loginSlice.isLoggedIn !== null)
      return state.loginSlice.isLoggedIn;
    else return null;
  });
  let userType = useSelector((state) =>
    state.loginSlice.userType ? state.loginSlice.userType : null
  );

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const isValidPassword = (password) => (password !== "" ? true : false);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetch("https://ipinfo.io/?format=json&token=9797c42b93078a")
      .then((results) => results.json())
      .then((data) => {
        // console.log("<<<<<<<<<<< data goes here >>>>>>>>>", data);
        dispatch(setIpinfo(data));
      });
  }, []);

  const validate = (value) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const isOk = strongRegex.test(value);
    console.log("isOk", isOk);
    if (!isOk) {
      setWrongPassword(true);

    } else {
      setWrongPassword(false);
    }
  };

  //////////////////////////////// Form Submission //////////////////////////////////////
  const onSubmit = (data) => {
    let reqObj = {
      email: data.email,
      password: data.password,
      login_data: ipdetails,
      login_time: Math.round(new Date().getTime()),
    };

    // if (!wrongPassword) {
    setLoginClicked(true);
    dispatch(login(reqObj));
    setLoading(true);
    // } 
  };

  store.subscribe(() => {
    const storedata = store.getState();
    // console.log("storedata login ", storedata.loginSlice)
    managenav(
      storedata,
      setuserInfoCookie,
      setUserTokenCookie,
      setLastLoginTimeCookie,
      navigate
    );
    if (storedata && storedata.loginSlice) {
      setMessage(storedata.loginSlice.message)
      if (storedata.loginSlice.errorMessage && storedata.loginSlice.errorMessage === "email is required for login") {
        setLoginExpired(true);
        setMessage(storedata.loginSlice.errorMessage)
      }
      if (storedata.loginSlice.success && storedata.dashboardReducer.gettraingcatagorydataSuccess) {
        setOpen(true);
      }
      if (storedata.loginSlice.error && storedata.loginSlice.message) {
        setOpen(true);
        setLoading(false);
      }
      // if (storedata.loginSlice.success && storedata.loginSlice.message === "Logout Completed Successfully") {
      //   setOpen(true);
      // }
    }
  });
  useEffect(() => {
    if (
      isLoggedIn !== null &&
      isLoggedIn !== undefined &&
      isLoggedIn === true &&
      loginClicked
    ) {
      // setOpen(true);
      // if (userType && userType === "organiser") {
      // } else {
      let reqbody = {
        source: "traning_list",
        condition: {
          limit: 100,
          skip: 0,
        },
        sort: {
          field: "priority",
          type: "asc",
        },
        searchcondition: {
          status: 1,
          show_on_dashboard: 1,
        },
        project: {},
        token: "",
        email: "",
      };
      dispatch(setLoader({ loader: true }));
      dispatch(gettraingcatagorydata(reqbody));
      setOpen(true);
      // }
    } else {
      // setOpen(false);
      // setLoading(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (resolved === true && loginClicked) {
      setLoading(false);
      navigate("/dashboard");
      dispatch(setResloved({ path: "/dashboard", resolve: true })); // Setting store as resolved page
      dispatch(setLoader()); // Stopping Loader     
    }
  }, [resolved]);

  return (
    <div className="loginMain_wrpr">
      <div className="login_bodyBG_block1 trbu"></div>

      <div className="login_bodyBG_block2 trbu"></div>

      <div className="login_bodyBG_block3 trbu"></div>

      <Grid container className="login_body">
        <Grid item className="loginLogoSection" md={6} sm={12}>
          <div className="logo-Wrpr">
            <img
              src={`${process.env.REACT_APP_IMG_URL}iWC_LoginLogo.png`}
              alt=""
            />
          </div>
        </Grid>

        <Grid item className="login_Section" md={6} sm={12}>
          <Grid className="login_main_wrpper">
            <div className="logo-Wrpr">
              <img
                src={`${process.env.REACT_APP_IMG_URL}iWC_LoginLogo.png`}
                alt=""
              />
            </div>

            <Typography component="h1" variant="h5">
              Login to IWC
            </Typography>
            <Box sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  }}
                  render={() => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      type="text"
                      autoFocus
                      onChange={(e) => {
                        setValue("email", e.target.value?.toLowerCase().trim());
                        clearErrors("email");
                        dispatch(clearMessageIsLoggedIn());
                        validate(null);
                      }}
                      error={
                        Object.keys(errors).length > 0 && errors.email != null
                      }
                      helperText={
                        errors.email && errorMessages[errors.email.type]
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" edge="end">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="password"
                  rules={{ required: true, validate: isValidPassword }}
                  render={() => (
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      error={
                        Object.keys(errors).length > 0 &&
                        errors.password != null
                      }
                      helperText={
                        errors.password && errorMessages[errors.password.type]
                      }
                      onChange={(e) => {
                        setValue("password", e.target.value);
                        clearErrors("password");
                        dispatch(clearMessageIsLoggedIn());
                        validate(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <div className="frgt_passWrpr" container>
                  {/* <Grid item xs /> */}

                  <Link className="forgot_password" to="/forget-password">
                    Forgot Password?
                  </Link>
                </div>



                {/* {wrongPassword && (
                  <p className="error">
                    {" "}
                    Password field must be One Number, One Capitalized,One
                    Special Cahracter & 8 Chracters
                  </p>
                )} */}
                <Button
                  className="passBttn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  disabled={loading}
                >
                  <span> Login </span>
                </Button>
              </form>


              {/* <Grid container>
                <Grid item xs />
                <Grid item display="flex" alignItems="center">
                  <Link className="forgot_password" to="/forgot-password">
                    Forgot Password?
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Grid>
          {/* <div className="login_page_main"> */}
          {/* <div className="css-shadow1"></div>
        <div className="css-shadow2"></div> */}

          {/* <div className="login_maindiv"> */}

          {/* <div> */}
          {/* <app-traing-center data={JSON.stringify(trainingdata)}></app-traing-center> */}
          {/* </div> */}

          {/* <Grid container className="login_mainBlock"></Grid> */}

          {/* {loading && <LinearProgress sx={{ margin: "5px" }} />} */}

          {/* </div> */}

          {/* {message !== "" && message !== null ? ( */}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={2000000}
            onClose={handleClose}
            message={message}
            
            key="bottomcenter"
          />
          {/* // ) : (
          //   ""
          // )} */}

          {/* <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={isLoggedIn ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message && message}
          </Alert>
        </Snackbar>      */}

          {/* </div> */}
          {loading && <app-progressbar class="loginLoader" />}

        </Grid>
      </Grid>
    </div>
  );
}

function managenav(
  tempstore,
  setuserInfoCookie,
  setUserTokenCookie,
  setLastLoginTimeCookie,
  navigate
) {
  if (
    tempstore.loginSlice.userInfo !== undefined &&
    tempstore.loginSlice.userInfo._id !== null &&
    tempstore.loginSlice.userInfo._id !== undefined
  ) {
    setuserInfoCookie(
      "userInfo",
      JSON.stringify(tempstore.loginSlice.userInfo),
      { path: "/" }
    );

    setUserTokenCookie("token", tempstore.loginSlice.token, { path: "/" });

    setLastLoginTimeCookie(
      "lastLoginTime",
      tempstore.loginSlice.last_login_time,
      { path: "/" }
    );
  }
}
