import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCategoryLessons = createAsyncThunk("training/rightblock/list", async (reqbody) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqbody)
  };
  // console.log("process.env==>", process.env);
  const response = await fetch(process.env.REACT_APP_API_URL + "api1/lessonlist-by-catagory", requestOptions);
  const respdata = await response.json();
  // console.log("respdata after endpoint fetch==>", respdata);
  return respdata;
});

const categoryRightBlock = createSlice({
  name: 'categoryRightBlockSLice',
  initialState: {
    categoryWiseLesson: {},
    loading: {}
  },
  reducers: {
    clearcategoryWiseLesson(state, action) {
      state.categoryWiseLesson = {}

  },
  },
  extraReducers: {
    [getCategoryLessons.rejected]: (state, action) => {
      if (action.meta.arg.searchcondition && action.meta.arg.searchcondition.traning_category) state.loading[action.meta.arg.searchcondition.traning_category === 1 ? "basicWoundCare" : "advancedWoundCare"] = false;
    },
    [getCategoryLessons.pending]: (state, action) => {
      if (action.meta.arg.searchcondition && action.meta.arg.searchcondition.traning_category) state.loading[action.meta.arg.searchcondition.traning_category === 1 ? "basicWoundCare" : "advancedWoundCare"] = true;
    },
    [getCategoryLessons.fulfilled]: (state, action) => {
      console.log("action====================================+>", action)
      if (action.meta.arg.searchcondition && action.meta.arg.searchcondition.traning_category) state.loading[action.meta.arg.searchcondition.traning_category === 1 ? "basicWoundCare" : "advancedWoundCare"] = false;
      state.categoryWiseLesson[action.meta.arg.searchcondition.traning_category === 1 ? "basicWoundCare" : "advancedWoundCare"] = action.payload.results.res
    },


  }
})

export default categoryRightBlock.reducer;
export const { clearcategoryWiseLesson} = categoryRightBlock.actions;