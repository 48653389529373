import { Button, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";
import { timeConverter } from "../../helper/helperFunctions"
import ListingTable from "../../listing/listing";
import { CalendarAllTabs } from "./CalendarAllTabs";

export const BookedSlot = () => {

    const cookies = new Cookies();
    const getcookies = cookies.getAll();

    const [connectgmailaccount, setconnectgmailaccount] = useState(getcookies.userInfo?.connected_gmail);
    const [googlecaledersynctime, setgooglecaledersynctime] = useState(getcookies.userInfo?.google_calendar_connect_time);

    const [bookedSlotList, setBookedSlotList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const bookedSlotObj = {
        tableId: "bookedSlotList",
        tableTitle: "Booked Appointment List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendarapi/booked-slot-list",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendarapi/booked-slot-list",
        },

        reqBody: {
            "condition": {
                "limit": 5,
                "skip": 0,
            },
            "sort": {
                "field": "_id",
                "type": "asc"
            },
            "searchcondition": {
                userid: getcookies.userInfo?.lead_organizer ? getcookies.userInfo.lead_organizer : getcookies.userInfo._id
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["slot_start_time_unix", "slot_end_time_unix", "speciality"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal  Deletemodal",
        },

        searchBarData: {
            heading: "Search Booked Slot",
            className: "ddsdsds",
            api: {
                url: process.env.REACT_APP_API_URL,
                endPoint: "calendarapi/booked-slot-list",
                tableCountEndpoint: "calendarapi/booked-slot-list",
                reqBody: {
                    // count:true,
                    // "source": "users",
                    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
                    condition: {
                        limit: 5,
                        skip: 0,
                    },
                    sort: {
                        type: "desc",
                        field: "_id",
                    },
                },
            },

            searchSettings: {
                datesearch: [
                    {
                        id: 0,
                        heading: "Search By Joining Date",
                        startdatelabel: "Start Date",
                        enddatelabel: "End Date",
                        className: "formGroup createdon_datetime countDiv__column--col4",
                        submit: "Search",
                        field: "createdon_datetime",
                    },
                ],
                selectsearch: [
                    {
                        id: 1,
                        label: "Search By Booking Status",
                        field: "booking_status",
                        type: "select",
                        className: "formGroup booking_status countDiv__column--col2",
                        values: ["Booking completed", "Sign-up completed"]
                    },
                    {
                        id: 2,
                        label: "Search By Type",
                        field: "type",
                        type: "select",
                        className: "formGroup type countDiv__column--col2",
                        values: ["Frontend", "NP"]
                    },


                ],
                textsearch: [
                    {
                        id: 3,
                        // heading: "Search By First Name",
                        label: "Search By Name ",
                        field: "fullname",
                        className: "formGroup name countDiv__column--col4",
                        value: "",
                    },
                    {
                        id: 4,
                        // heading: "Search By Email",
                        label: "Search By Email ",
                        field: "email",
                        className: "formGroup email countDiv__column--col6",
                        value: "",
                    },
                ],

            },
        },
    };

    var modifyTableHeaders =
        [
            { val: "fullname", name: "Name" },
            { val: "booking_status", name: "Booking Status" },
            { val: "practicename", name: "Practice" },
            { val: "email", name: "Email" },
            { val: "phone", name: "Phone" },
            { val: "slot_start_time_unix", name: "Appointment Start Time Unix" },
            { val: "slot_end_time_unix", name: "Appointment End Time Unix" },
            { val: "type", name: "Type" },
            { val: "website", name: "Website" },
            { val: "speciality", name: "Speciality" }
        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(bookedSlotObj.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendarapi/booked-slot-list",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setBookedSlotList([...respdata.results.res]);
        }
        setloaderFlag(false);
    }

    useEffect(() => {
        if (bookedSlotList && bookedSlotList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList();
        }

    }, [bookedSlotList]);


    const GOOGLE_AUTHENTICATION_URL = process.env.REACT_APP_API_ENV === "isDev" ? process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL : process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL

    return (
        <>
            <div className="mainContentWrapper form">
                <div className="ContentWrapper">
                    <div className="Formdiv">
                        {/* Login Form Start Here */}
                        <div className="FormBlock">
                            {/* connected email section */}
                            <CalendarAllTabs from={'bookedSlot'} />
                            {loaderFlag == true ? (
                                <Box sx={{ width: "100%", mt: "10px" }}>
                                    <app-progressbar />
                                </Box>
                            ) : (
                                ""
                            )}

                            <div className="reactTableWrapper bookedListWrpr">
                                {!loaderFlag && bookedSlotList !== undefined ? (
                                    <ListingTable
                                        tableData={bookedSlotObj}
                                        dataset={bookedSlotList}
                                        modifyHeaders={modifyTableHeaders}
                                    />
                                ) : ''}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}