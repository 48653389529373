import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import ListingTable from "../../../listing/listing";

export const EventSlotNotCreated = () => {
    const [eventSlotNotCreatedList, setEventSlotNotCreatedList] = useState([]);
    const [listFetchSuccess, setListFetchSuccess] = useState(false);
    const [loaderFlag, setloaderFlag] = useState(true);

    const eventSlotNotCreatedListObj = {
        tableId: "eventSlotNotCreatedLists",
        tableTitle: "Event Appointment Not Created List",
        showReload: true,
        showFilter: true,
        api: {
            url: process.env.REACT_APP_API_URL,
            endPoint: "calendarapi/event-slot-not-created",
            // deleteSingleUserEndpoint: "api/delete-user",
            tableCountEndpoint: "calendarapi/event-slot-not-created",
        },

        reqBody: {
            "condition": {
                "limit": 5,
                "skip": 0
            },
            "sort": {
                "field": "_id",
                "type": "desc"
            },
            "searchcondition": {
            },
            "count": false
        },
        deleteSingleUserReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },
        deleteMultipleReqBody: {
            source: "users",
            secret: "AZ|lepL`",
            // token: usersToken,
        },

        //******************************** By Default Rows per page option will be available**************************//

        rowsPerPageOptions: [5, 10, 20, 30],
        sortFields: ["timezone", "start_datetime_unix", "last_recreated"],
        paginationType: "stackPagination",
        enableRowActionLoader: true,
        customBtnHead: [
            // {
            //     id: "deleteMultiple",
            //     type: "delete_icon",
            //     title: "Delete",
            // },
            // {
            //     id: "editMultiple",
            //     type: "toggle_on_icon",
            //     title: "Status Change",
            // },
            // {
            //     id: "adduser",
            //     type: "add_icon",
            //     title: "ADD USER",
            //     className: "add_user_icon",
            //     default: true,
            // },
        ],
        buttons: [
        ],
        deleteModal: {
            modalClassName: "Delete Modal Deletemodal",
        },

        searchBarData: {
            heading: "Search Event Appointment Not Created List",
            api: {
                url: process.env.REACT_APP_API_URL,
                endPoint: "calendarapi/event-slot-not-created",
                tableCountEndpoint: "calendarapi/event-slot-not-created",
                reqBody: {
                    // count:true,
                    // "source": "users",
                    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYzNTZkNDFjZTE3MzAwMDhiOGFhYTUiLCJzZWNyZXQiOiJ6R3RpMnY1enYiLCJpYXQiOjE2Njc5Njg3MzV9.wzFi1CoHbv9QK5cUHbVgSL6LW8m6fR_BaILcT7OcHBQ",
                    condition: {
                        limit: 5,
                        skip: 0,
                    },
                    sort: {
                        type: "desc",
                        field: "_id",
                    },
                },
            },

            searchSettings: {
                datesearch: [
                    // {
                    //     id: 0,
                    //     heading: "Search By Joining Date",
                    //     startdatelabel: "Start Date",
                    //     enddatelabel: "End Date",
                    //     className: "formGroup createdon_datetime countDiv__column--col4",
                    //     submit: "Search",
                    //     field: "createdon_datetime",
                    // },
                ],
                selectsearch: [
                    // {
                    //     id: 1,
                    //     label: "Search By Timezone",
                    //     field: "timezone",
                    //     type: "select",
                    //     className: "formGroup timezone countDiv__column--col4",
                    //     values: ["-09:00|America/Anchorage", "-08:00|America/Los_Angeles", "-07:00|America/Phoenix", "-06:00|America/Chicago", "-05:00|America/New_York", "-10:00|Pacific/Honolulu", "+05:30|Asia/Kolkata"],
                    //     // value: "",
                    // },


                ],
                textsearch: [
                    {
                        id: 2,
                        // heading: "Search By First Name",
                        label: "Search By Event Title ",
                        field: "event_title",
                        className: "formGroup event_title countDiv__column--col4",
                        value: "",
                    },
                    // {
                    //     id: 3,
                    //     // heading: "Search By First Name",
                    //     label: "Search By description ",
                    //     field: "description",
                    //     className: "formGroup description countDiv__column--col4",
                    //     value: "",
                    // },
                ],

            },
        },
    };

    var modifyTableHeaders =
        [
            { val: "event_title", name: "Event Title" },
            { val: "timespan", name: "Timespan" },
            { val: "timezone", name: "Timezone" },
            { val: "slot_status", name: "Appointment Status" },
            { val: "start_datetime_unix", name: "Start Date Time", type: "dateTime", format: "DD/MM/YYYY hh:mm A" },
            { val: "last_recreated", name: "Last Re-created", type: "dateTime", format: "DD/MM/YYYY hh:mm A" }

        ];

    const getList = async () => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(eventSlotNotCreatedListObj.reqBody),
        };
        const response = await fetch(
            process.env.REACT_APP_API_URL + "calendarapi/event-slot-not-created",
            requestOptions
        );
        const respdata = await response.json();
        console.log("++++cv+++++++", respdata);
        if (respdata && respdata.status === "success") {
            setEventSlotNotCreatedList([...respdata.results.res])
        }
        setloaderFlag(false);
        // conflictingSlotFetched(true);
    }

    useEffect(() => {
        if (eventSlotNotCreatedList && eventSlotNotCreatedList.length === 0 && !listFetchSuccess) {
            setListFetchSuccess(true);
            getList()
        }

    }, [eventSlotNotCreatedList]);

    return (
        <>
            {loaderFlag == true ? (
                <Box sx={{ width: "100%", mt: "10px" }}>
                    <app-progressbar />
                </Box>
            ) : (
                ""
            )}

            <div className="reactTableWrapper miscList">

                {!loaderFlag && eventSlotNotCreatedList !== undefined ? (
                    <ListingTable
                        tableData={eventSlotNotCreatedListObj}
                        dataset={eventSlotNotCreatedList}
                        modifyHeaders={modifyTableHeaders}
                    />
                ) : ''}

            </div>
        </>
    )
}